import { FC } from 'react';

import CollapseButtonTooltip from './CollapseButtonTooltip';
import { Button, StyledLeftCircleOutlined } from './styled';
import { TCollapseButtonProps } from './types';

const CollapseButton: FC<TCollapseButtonProps> = ({
  isCollapsed,
  handleClickCollapse,
}) => (
  <Button
    className='collapse-button'
    isCollapsed={isCollapsed}
    onClick={handleClickCollapse}
  >
    <CollapseButtonTooltip isCollapsed={isCollapsed} />
    <StyledLeftCircleOutlined isCollapsed={isCollapsed} />
  </Button>
);

export default CollapseButton;
