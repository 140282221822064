import { FC } from 'react';
import { Select as AntdSelect } from 'antd';

import InputBase from 'components/Inputs/components/InputBase';

import { ISelectProps } from './types';
import { StyledSelect } from './styled';

const { Option } = AntdSelect;

const Select: FC<ISelectProps> = ({
  id,
  disablePopupContainer = false,
  allowClear = true,
  required = true,
  showSearch = false,
  className,
  disabled,
  selectConfig,
  labelText,
  placeholder,
  value,
  errorMessage,
  notFoundContent,
  dropdownMatchSelectWidth,
  optionLabelProp,
  optionFilterProp,
  onChange,
}) => (
  <InputBase
    disabled={disabled}
    required={required}
    placeholder={placeholder}
    labelText={labelText}
    errorMessage={errorMessage}
    value={String(value)}
  >
    <StyledSelect
      allowClear={allowClear}
      showSearch={showSearch}
      id={id}
      disabled={disabled}
      className={className}
      placeholder={placeholder}
      status={errorMessage && 'error'}
      value={value}
      getPopupContainer={
        !disablePopupContainer
          ? (triggerNode: HTMLElement) => triggerNode?.parentNode as HTMLElement
          : undefined
      }
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      optionFilterProp={optionFilterProp}
      optionLabelProp={optionLabelProp}
      notFoundContent={notFoundContent}
      onChange={onChange}
    >
      {selectConfig.map(
        ({ value, label, disabled, searchBy, selectedLabel }) => (
          <Option
            key={value}
            value={value}
            disabled={disabled}
            searchBy={searchBy}
            selectedLabel={selectedLabel}
          >
            {label}
          </Option>
        ),
      )}
    </StyledSelect>
  </InputBase>
);

export default Select;
