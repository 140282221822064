import { TFormikConfig } from 'types/formik';

export enum ForgotPasswordField {
  email = 'email',
}

export interface IForgotPasswordValues {
  [ForgotPasswordField.email]: string;
}

export interface IForgotPasswordProps {
  formik: TFormikConfig<IForgotPasswordValues>;
  isSendResetLinkError?: boolean;
  errorResetLinkMessage?: string;
}

export interface IForgotPasswordProps {
  resendButtonTimer: string;
  formik: TFormikConfig<IForgotPasswordValues>;
  errorMessage: string | null;
  isFormVisible: boolean;
  isSendLinkLoading: boolean;
  onBackLinkClick: () => void;
  onResendButtonClick: () => void;
}
