import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';

import { AUTOFILLED_INPUT_SELECTOR } from './constants';
import { TLabelConfigProps } from './types';

export const LabelWrapper = styled(
  ({ isError: _isError, isOccupied: _isOccupied, children, ...props }) => (
    <label {...props}>{children}</label>
  ),
)<TLabelConfigProps>(({ isError, isOccupied }) => ({
  display: 'block',
  color: COLORS.gray700,
  textAlign: 'left',
  '&:hover > span': {
    color: `${
      !isOccupied ? COLORS.gray600 : isError ? COLORS.red500 : COLORS.blue500
    }`,
  },
}));

export const InputWrapper = styled('div')({
  position: 'relative',
});

const occupiedLabelStyles = {
  top: 0,
  fontSize: '14px',
  backgroundColor: COLORS.white,
};

export const LabelText = styled(
  ({
    isOccupied: _isOccupied,
    isError: _isError,
    isFocused: _isFocused,
    children,
    disabled: _disabled,
    ...props
  }) => <span {...props}>{children}</span>,
)<TLabelConfigProps>(({ isOccupied, isError, isFocused, disabled }) => ({
  position: 'absolute',
  zIndex: 2,
  top: '50%',
  left: '12px',
  padding: '0 4px',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: 1,
  color: COLORS.gray600,
  pointerEvents: 'none',
  transform: 'translateY(-50%)',
  transition: '0.3s',
  [`&:has(+ ${AUTOFILLED_INPUT_SELECTOR}, + * > ${AUTOFILLED_INPUT_SELECTOR})`]:
    occupiedLabelStyles,
  ...(isOccupied && {
    ...occupiedLabelStyles,
    color: `${
      isError ? COLORS.red500 : isFocused ? COLORS.blue500 : COLORS.gray600
    }`,
    ...(disabled && {
      background: `linear-gradient(to bottom, ${COLORS.white} 50%, ${COLORS.gray100} 50%)`,
    }),
  }),
}));
