import { AxiosError } from 'axios';
import { FC, useEffect, useState } from 'react';

import { useForgotPassword, IRequestErrorPayload } from 'api/auth';
import { EMPTY_STRING } from 'constants/common';
import { SERVER_ERROR_STRING } from 'constants/messages';
import { useCountdown, useFormik } from 'hooks';
import { openNotificationWithIcon } from 'utils/showNotification';
import { NotificationType } from 'types/notifications';

import {
  FORGOT_PASSWORD_TEXT,
  INITIAL_FORGOT_PASSWORD_VALUES,
} from './constants';
import ForgotPassword from './ForgotPassword';
import { forgotPasswordValidationSchema } from './validation';
import { IForgotPasswordValues } from './types';

const ForgotPasswordContainer: FC = () => {
  const { timeLeft, reset: resetCountdown } = useCountdown();

  const handleSubmit = (forgotPasswordPayload: IForgotPasswordValues) => {
    if (
      timeLeft !== EMPTY_STRING &&
      forgotPasswordPayload.email === sentEmail
    ) {
      openNotificationWithIcon(
        NotificationType.error,
        FORGOT_PASSWORD_TEXT.forgotPasswordAlert,
      );

      return;
    }
    setErrorMessage(null);
    sendLinkMutate(forgotPasswordPayload);
  };

  const formik = useFormik({
    initialValues: INITIAL_FORGOT_PASSWORD_VALUES,
    validationSchema: forgotPasswordValidationSchema,
    onSubmit: handleSubmit,
  });

  const [isFormVisible, setFormVisible] = useState(true);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [sentEmail, setSentEmail] = useState<string>(EMPTY_STRING);

  const onBackLinkClick = () => {
    setSentEmail(formik.values.email);
    setFormVisible(true);
  };

  const onResendButtonClick = () => {
    handleSubmit(formik.values);
  };

  const showResendView = () => {
    setFormVisible(false);
    resetCountdown();
    setErrorMessage(null);
    setSentEmail(EMPTY_STRING);
  };

  const handleError = (error: AxiosError<IRequestErrorPayload>) => {
    const errorResetLinkMessage =
      error?.response?.data?.message || error?.message;

    if (errorResetLinkMessage.includes(SERVER_ERROR_STRING)) {
      setFormVisible(false);
      if (errorMessage) {
        resetCountdown();
      }
      setErrorMessage(errorResetLinkMessage);
    } else {
      openNotificationWithIcon(NotificationType.error, errorResetLinkMessage);
    }
  };

  const { mutate: sendLinkMutate, isLoading: isSendLinkLoading } =
    useForgotPassword({
      onSuccess: showResendView,
      onError: handleError,
    });

  useEffect(() => {
    return () => {
      setErrorMessage(null);
    };
  }, []);

  return (
    <ForgotPassword
      formik={formik}
      resendButtonTimer={timeLeft}
      errorMessage={errorMessage}
      isFormVisible={isFormVisible}
      isSendLinkLoading={isSendLinkLoading}
      onBackLinkClick={onBackLinkClick}
      onResendButtonClick={onResendButtonClick}
    />
  );
};

export default ForgotPasswordContainer;
