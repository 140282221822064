import { FC } from 'react';
import { Spin } from 'antd';

import HelmetPageTitle from 'components/HelmetPageTitle';
import { ContentCard } from 'components/UnauthorizedPages';
import CustomAlert from 'components/CustomAlert';

import { MENU_TITLE } from 'constants/menu';

import ResetPasswordForm from './ResetPasswordForm';
import { RESET_PASSWORD_TEXT, BACK_TO_FORGOT_CONFIG } from './constants';
import { IResetPasswordProps } from './types';

const ResetPassword: FC<IResetPasswordProps> = ({
  checkResetExpiredErrorMessage,
  isCheckResetExpiredLoading,
  isCheckResetExpiredError,
}) => (
  <HelmetPageTitle title={MENU_TITLE.resetPassword}>
    {isCheckResetExpiredLoading ? (
      <Spin size='large' />
    ) : (
      <ContentCard
        title={RESET_PASSWORD_TEXT.pageTitle}
        subTitle={RESET_PASSWORD_TEXT.pageSubTitle}
        backLinkConfig={BACK_TO_FORGOT_CONFIG}
      >
        {isCheckResetExpiredError ? (
          <CustomAlert type='error' message={checkResetExpiredErrorMessage} />
        ) : (
          <ResetPasswordForm
            checkResetExpiredErrorMessage={checkResetExpiredErrorMessage}
            isCheckResetExpiredError={isCheckResetExpiredError}
          />
        )}
      </ContentCard>
    )}
  </HelmetPageTitle>
);

export default ResetPassword;
