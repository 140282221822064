import styled from 'styled-components';
import { LeftCircleOutlined } from '@ant-design/icons';

import { HEADER_HEIGHT } from 'constants/menu';
import { COLORS } from 'constants/colorPalette';
import { TIsCollapsed } from 'components/Layouts/AuthorizedLayout/types';

export const Button = styled(
  ({ isCollapsed: _isCollapsed, children, ...props }) => (
    <button {...props}>{children}</button>
  ),
)<TIsCollapsed>(({ isCollapsed }) => ({
  position: 'absolute',
  top: `${HEADER_HEIGHT}px`,
  right: '-22px',
  padding: 0,
  color: COLORS.blue500,
  lineHeight: 0,
  border: 'none',
  borderRadius: '50%',
  background: COLORS.white,
  transform: 'translate(-50%, -60%)',
  transition: '.2s ease',
  zIndex: 1,
  ...(!isCollapsed && {
    opacity: 0,
    visibility: 'hidden',
    pointerEvents: 'none',
  }),
  '&:hover': {
    color: COLORS.blue600,
    cursor: 'pointer',
    '& .collapse-button-tooltip': {
      opacity: 1,
      visibility: 'visible',
    },
  },
  '@media (max-width:1024px)': {
    display: 'none',
  },
}));

export const StyledLeftCircleOutlined = styled(
  ({ isCollapsed: _isCollapsed, ...props }) => (
    <LeftCircleOutlined {...props} />
  ),
)<TIsCollapsed>(({ isCollapsed }) => ({
  transform: 'rotate(0)',
  transition: '.2s ease',
  '& svg': {
    width: '22px',
    height: '22px',
  },
  ...(isCollapsed && {
    transform: 'rotate(180deg)',
  }),
}));
