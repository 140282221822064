import { createContext } from 'react';

import { EMPTY_ARRAY } from 'constants/common';

import { IAppContext } from './types';

const CONTEXT_INITIAL_VALUES = {
  socket: null,
  highlightedCertificates: EMPTY_ARRAY,
  setHighlightedCertificates: () => null,
};

const AppContext = createContext<IAppContext>(CONTEXT_INITIAL_VALUES);

export default AppContext;
