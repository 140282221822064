import { ForwardRefRenderFunction, forwardRef } from 'react';

import { RoutesList } from 'components/Routes';
import { FormScrollNavigationId } from 'pages/DealerDetails/FormNavigation/types';

import { StyledContent, StyledLayout, StyledMainLayout } from './styled';
import { IMainLayoutProps } from './types';
import SideMenu from '../SideMenu';
import Header from '../Header';

const MainLayout: ForwardRefRenderFunction<HTMLElement, IMainLayoutProps> = (
  { userProfile, isCollapsed, clearHighlighting, ...props },
  ref,
) => {
  const { role, firstName, lastName, avatar } = userProfile;

  const userFullName = `${firstName} ${lastName}`;

  return (
    <StyledMainLayout onClick={clearHighlighting}>
      <SideMenu userRole={role} isCollapsed={isCollapsed} {...props} />
      <StyledLayout>
        <Header
          userRole={role}
          userFullName={userFullName}
          userAvatar={avatar?.url}
          isCollapsed={isCollapsed}
          {...props}
        />
        <StyledContent id={FormScrollNavigationId.scrollContainer} ref={ref}>
          <RoutesList />
        </StyledContent>
      </StyledLayout>
    </StyledMainLayout>
  );
};

export default forwardRef(MainLayout);
