export const NO_LIMIT_PARAM_VALUE = 0;

export const HIDE_NOTIFICATIONS_DURATION = 3000;

export const NOTIFICATIONS_TEXT = {
  COMMON_ACCOUNT_SUSPENDED:
    'Your account has been suspended. Please, contact system admin or our support team for more information.',
  COMMON_EMAIL_CHANGED:
    'Your account email has been updated. Please, check your new email inbox and use your new credentials for sign in, or contact system admin or our support team for further assistance.',
  WRONG: 'Something went wrong. Please try again',
};
