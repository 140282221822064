import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router';

import { QUERY_KEYS } from 'constants/queryKeys';
import { NULL_TYPE_VALUE } from 'constants/common';
import { apiClient, queryClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { TAxiosRequestError } from 'api/types';
import { STATUS_CODES } from 'constants/errorCodes';
import { APP_ROUTE } from 'constants/appRoutes';

import { IUseSignOutResponse } from './types';

const useSignOut = (): UseMutationResult<
  IUseSignOutResponse,
  AxiosError,
  void
> => {
  const navigateTo = useNavigate();

  const handleSignOutSuccess = () => {
    queryClient.setQueryData(QUERY_KEYS.USER_PROFILE, NULL_TYPE_VALUE);

    navigateTo(APP_ROUTE.browserBase);
  };

  const handleSignOutError = (error: TAxiosRequestError) => {
    if (error.code !== STATUS_CODES.ERR_NETWORK_CODE) {
      queryClient.setQueryData(QUERY_KEYS.USER_PROFILE, NULL_TYPE_VALUE);
    }
  };

  return useMutation(
    async () => {
      const { data: tokenResponse } = await apiClient.post(ApiRoutes.SIGN_OUT);

      return tokenResponse;
    },
    { onSuccess: handleSignOutSuccess, onError: handleSignOutError },
  );
};

export default useSignOut;
