import { COLORS } from 'constants/colorPalette';

import { TBadgeType } from './types';

export const BADGE_BG_COLOR_LOOKUP: Record<TBadgeType, string> = {
  deleted: COLORS.gray100,
  archived: COLORS.blue100,
  success: COLORS.green300,
  error: COLORS.red100,
  'error-light': COLORS.red50,
  'success-light': COLORS.green300,
  'deleted-light': COLORS.gray100,
};

export const BADGE_FG_COLOR_LOOKUP: Record<TBadgeType, string> = {
  deleted: COLORS.gray800,
  archived: COLORS.blue700,
  success: COLORS.green900,
  error: COLORS.red600,
  'error-light': COLORS.red600,
  'success-light': COLORS.green700,
  'deleted-light': COLORS.gray600,
};
