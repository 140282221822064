import { FC } from 'react';

import { MENU_TITLE } from 'constants/menu';
import TechnicalMessage from 'pages/TechnicalMessage';

import { NOT_FOUND_TEXT } from './constants';
import { INotFoundProps } from './types';

const NotFound: FC<INotFoundProps> = ({ subTitle }) => (
  <TechnicalMessage
    defaultPageTitle={MENU_TITLE.notFound}
    errorCode={NOT_FOUND_TEXT.ERROR_CODE}
    title={NOT_FOUND_TEXT.CONTENT_TITLE}
    subTitle={subTitle ?? NOT_FOUND_TEXT.CONTENT_SUBTITLE}
  />
);

export default NotFound;
