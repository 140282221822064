import { AUFlag, CAFlag, GBFlag, NZFlag, USFlag } from 'assets/vectors/flags';

import CountryCodeLabel from './CountryCodeLabel';
import { TCountryCodeLookups } from './types';

export const DROPDOWN_SELECT_WIDTH = 172;

export const SELECTED_OPTION_RENDER = 'selectedLabel';
export const DEFAULT_SELECT_PLACEHOLDER = 'Select code';

export const DEFAULT_LABELS_TEXT = {
  PHONE_NUMBERS: 'Phone number',
  COUNTRY_CODE: 'Code',
};

export const COUNTRY_CODES_SELECT = [
  {
    value: 'US',
    label: <CountryCodeLabel countryCode='US' />,
    selectedLabel: (
      <CountryCodeLabel withCountryName={false} countryCode='US' />
    ),
  },
  {
    value: 'CA',
    label: <CountryCodeLabel countryCode='CA' />,
    selectedLabel: (
      <CountryCodeLabel withCountryName={false} countryCode='CA' />
    ),
  },
  {
    value: 'GB',
    label: <CountryCodeLabel countryCode='GB' />,
    selectedLabel: (
      <CountryCodeLabel withCountryName={false} countryCode='GB' />
    ),
  },
  {
    value: 'AU',
    label: <CountryCodeLabel countryCode='AU' />,
    selectedLabel: (
      <CountryCodeLabel withCountryName={false} countryCode='AU' />
    ),
  },
  {
    value: 'NZ',
    label: <CountryCodeLabel countryCode='NZ' />,
    selectedLabel: (
      <CountryCodeLabel withCountryName={false} countryCode='NZ' />
    ),
  },
];

export const COUNTRY_CODE_FLAG: TCountryCodeLookups<JSX.Element> = {
  US: <USFlag />,
  CA: <CAFlag />,
  GB: <GBFlag />,
  AU: <AUFlag />,
  NZ: <NZFlag />,
};

export const COUNTRY_CODE_VALUE: TCountryCodeLookups = {
  US: '+1',
  CA: '+1',
  GB: '+44',
  AU: '+61',
  NZ: '+64',
};

export const COUNTRY_CODE_NAME: TCountryCodeLookups = {
  US: 'USA',
  CA: 'Canada',
  GB: 'UK',
  AU: 'Australia',
  NZ: 'New Zealand',
};

export const DEFAULT_MASK = '_';

export const PHONE_NUMBERS_FORMATS: TCountryCodeLookups = {
  US: '(###) ###-####',
  CA: '(###) ###-####',
  NZ: '###-###-####',
  AU: '(##) #### ####',
  GB: '#### ######',
};
