import { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { APP_ROUTE } from 'constants/appRoutes';

import { DASHBOARD_ROLES } from '../constants';
import { IRedirectFromBaseRouteProps } from './types';

const RedirectFromBaseRoute: FC<IRedirectFromBaseRouteProps> = ({
  userRole,
  isAuthorized,
}) => {
  const appRedirectPath = DASHBOARD_ROLES.includes(userRole)
    ? APP_ROUTE.dashboard
    : APP_ROUTE.serialNumbers;

  const navigatePath = isAuthorized ? appRedirectPath : APP_ROUTE.signIn;

  return <Navigate replace to={navigatePath} />;
};

export default RedirectFromBaseRoute;
