import { BaseSyntheticEvent } from 'react';

export enum Enviroments {
  development = 'development',
  test = 'test',
  staging = 'staging',
}

export enum Size {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum Placement {
  top = 'top',
  right = 'right',
}

export interface ITooltipChildrenHandlers {
  onMouseEnter?: (event: BaseSyntheticEvent) => void;
  onMouseLeave?: (event: BaseSyntheticEvent) => void;
}

export type TSelect = {
  label: string;
  value: string;
  disabled?: boolean;
};

export type TFilters = { [key: string]: string | null };

export type TFilterChangeHandler = (
  field: string,
  labelPrefix?: string,
) => (value: unknown) => void;

export type TEmptyObject = Record<string, never>;
