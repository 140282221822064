import { Spin } from 'antd';
import { FC } from 'react';

import { SpinWrapper } from './styled';
import { ILoaderProps } from './types';

const Loader: FC<ILoaderProps> = ({ size, ...props }) => (
  <SpinWrapper {...props}>
    <Spin size={size || 'large'} />
  </SpinWrapper>
);

export default Loader;
