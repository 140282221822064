import { FC } from 'react';

import { useGetUserFromQueryContext } from 'hooks';

import UnregisteredRouteRedirect from './UnregisteredRouteRedirect';

const UnregisteredRouteRedirectContainer: FC = (props) => {
  const userProfile = useGetUserFromQueryContext();

  const isAuthorized = Boolean(userProfile?._id);

  return <UnregisteredRouteRedirect isAuthorized={isAuthorized} {...props} />;
};

export default UnregisteredRouteRedirectContainer;
