import { FC } from 'react';
import { Image } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import { TImageWithZoomProps } from './types';

const ImageWithZoom: FC<TImageWithZoomProps> = (props) => (
  <Image
    width={38}
    height={20}
    preview={{ mask: <EyeOutlined /> }}
    {...props}
  />
);

export default ImageWithZoom;
