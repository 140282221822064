import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';

export const FooterWrapper = styled('div')({
  display: 'flex',
});

export const FooterTip = styled('span')({
  paddingBottom: '50px',
  fontWeight: 500,
  color: COLORS.gray500,
  '@media (max-width: 1024px)': {
    paddingBottom: '30px',
  }
});
