import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';
import CustomAlert from 'components/CustomAlert';
import Button from 'components/Button';

export const InputWrapper = styled('div')({
  marginBottom: '40px',
});

export const StyledSendLinkButton = styled(Button)({
  width: '100%',
  marginBottom: '20px',
  '&.ant-btn-icon-only.ant-btn-lg': {
    width: '100%',
  },
});

export const ResendButtonNotAvailableText = styled('p')({
  marginBottom: '20px',
  fontSize: '16px',
  fontWeight: 500,
  color: COLORS.gray600,
  textAlign: 'center',
  whiteSpace: 'pre-line',
  '@media (max-width: 1024px)': {
    marginBottom: '170px',
  },
});

export const ResendButtonWrapper = styled('div')({
  width: '100%',
  color: COLORS.gray900,
  textAlign: 'center',
  marginBottom: '20px',
  '@media (max-width: 1024px)': {
    marginBottom: '160px',
  },
});

export const StyledResendButton = styled(Button)({
  height: 'inherit',
  padding: 0,
});

export const StyledAlert = styled(CustomAlert)({
  marginBottom: '32px',
  whiteSpace: 'pre-line',
});

export const EmailSentText = styled('p')({
  fontSize: '16px',
  fontWeight: 500,
  marginBottom: '32px',
  color: COLORS.gray700,
  textAlign: 'center',
  '& .highlighted': {
    fontWeight: 800,
    wordBreak: 'break-word',
  },
});
