import { FC, SyntheticEvent } from 'react';

import { StyledCroppedTooltipTitle } from './styled';

const CroppedTooltipTitle: FC<{ title: React.ReactNode }> = ({ title }) => {
  const handleClick = (e: SyntheticEvent) => e.stopPropagation();

  return (
    <StyledCroppedTooltipTitle onClick={handleClick}>
      {title}
    </StyledCroppedTooltipTitle>
  );
};

export default CroppedTooltipTitle;
