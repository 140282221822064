import { FC } from 'react';

import { useGetUserFromQueryContext } from 'hooks';

import RedirectFromBaseRoute from './RedirectFromBaseRoute';

const RedirectFromBaseRouteContainer: FC = (props) => {
  const userProfile = useGetUserFromQueryContext();

  const userRole = userProfile?.role;
  const isAuthorized = Boolean(userProfile?._id);

  return (
    <RedirectFromBaseRoute
      userRole={userRole}
      isAuthorized={isAuthorized}
      {...props}
    />
  );
};

export default RedirectFromBaseRouteContainer;
