import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';

import { APP_ROUTE } from 'constants/appRoutes';
import {
  AuthRedirectRoute,
  RedirectFromBaseRoute,
  RoleRoute,
  UnregisteredRouteRedirect,
} from 'components/Routes';
import {
  Dashboard,
  Requests,
  NewRequestDetails,
  CompleteRequestDetails,
  SerialNumbers,
  Certificates,
  Customers,
  CreateCustomer,
  CustomerInfo,
  EditCustomerBaseInfo,
  Employees,
  Dealers,
  CreateDealer,
  ViewDealerDetails,
  SerialNumberDetails,
  Subscriptions,
  NoPermissions,
  Profile,
  SignIn,
  ForgotPassword,
  ResetPassword,
  EditEmployeeDetails,
  CreateEmployee,
  CurrencyAnalytics,
  DealersAnalytics,
  StatesAnalytics,
  HoldingsAnalytics,
} from 'pages';
import { UserRoles } from 'types/user';

import { DASHBOARD_ROLES } from '../constants';

const RoutesList: FC = () => {
  return (
    <Routes>
      <Route index element={<RedirectFromBaseRoute />} />
      <Route
        element={
          <AuthRedirectRoute
            authCheckType='unauthorized'
            redirectPath={APP_ROUTE.serialNumbers}
          />
        }
      >
        <Route path={APP_ROUTE.signIn} element={<SignIn />} />
        <Route path={APP_ROUTE.forgotPassword} element={<ForgotPassword />} />
        <Route path={APP_ROUTE.resetPassword} element={<ResetPassword />} />
      </Route>
      <Route
        element={
          <AuthRedirectRoute
            authCheckType='authorized'
            redirectPath={APP_ROUTE.signIn}
          />
        }
      >
        <Route element={<RoleRoute acceptedRoles={DASHBOARD_ROLES} />}>
          <Route path={APP_ROUTE.dashboard} element={<Dashboard />} />
        </Route>
        <Route path={APP_ROUTE.serialNumbers}>
          <Route index element={<SerialNumbers />} />
          <Route
            path={APP_ROUTE.serialNumberDetails}
            element={<SerialNumberDetails />}
          />
        </Route>
        <Route path={APP_ROUTE.requests}>
          <Route
            element={
              <RoleRoute
                acceptedRoles={[
                  UserRoles.super_admin,
                  UserRoles.admin,
                  UserRoles.manager,
                  UserRoles.vault,
                ]}
              />
            }
          >
            <Route index element={<Requests />} />
            <Route
              path={APP_ROUTE.newRequestDetails}
              element={<NewRequestDetails />}
            />
          </Route>
          <Route
            path={APP_ROUTE.completeRequestDetails}
            element={<CompleteRequestDetails />}
          />
        </Route>
        <Route path={APP_ROUTE.profile} element={<Profile />} />
        <Route
          element={
            <RoleRoute
              acceptedRoles={[UserRoles.super_admin, UserRoles.admin]}
            />
          }
        >
          <Route path={APP_ROUTE.employees}>
            <Route index element={<Employees />} />
            <Route
              path={APP_ROUTE.createEmployee}
              element={<CreateEmployee />}
            />
            <Route
              path={APP_ROUTE.employeeInfo}
              element={<EditEmployeeDetails />}
            />
          </Route>
        </Route>
        <Route
          element={
            <RoleRoute
              acceptedRoles={[
                UserRoles.super_admin,
                UserRoles.admin,
                UserRoles.manager,
              ]}
            />
          }
        >
          <Route path={APP_ROUTE.certificates} element={<Certificates />} />
          <Route path={APP_ROUTE.dealers}>
            <Route index element={<Dealers />} />
            <Route
              element={
                <RoleRoute
                  acceptedRoles={[UserRoles.super_admin, UserRoles.admin]}
                />
              }
            >
              <Route path={APP_ROUTE.createDealer} element={<CreateDealer />} />
            </Route>
            <Route
              path={APP_ROUTE.viewDealerDetails}
              element={<ViewDealerDetails />}
            />
          </Route>
        </Route>
        <Route
          element={
            <RoleRoute
              acceptedRoles={[
                UserRoles.super_admin,
                UserRoles.admin,
                UserRoles.manager,
                UserRoles.sales,
              ]}
            />
          }
        >
          <Route
            path={APP_ROUTE.currencyAnalytics}
            element={<CurrencyAnalytics />}
          />
          <Route
            path={APP_ROUTE.dealerAnalytics}
            element={<DealersAnalytics />}
          />
          <Route
            path={APP_ROUTE.stateAnalytics}
            element={<StatesAnalytics />}
          />
          <Route
            path={APP_ROUTE.holdingsAnalytics}
            element={<HoldingsAnalytics />}
          />
        </Route>
        <Route
          element={
            <RoleRoute
              acceptedRoles={[
                UserRoles.super_admin,
                UserRoles.admin,
                UserRoles.manager,
                UserRoles.customer_service,
                UserRoles.sales,
              ]}
            />
          }
        >
          <Route path={APP_ROUTE.customers}>
            <Route index element={<Customers />} />
            <Route
              path={APP_ROUTE.createCustomer}
              element={<CreateCustomer />}
            />
            <Route path={APP_ROUTE.customerInfo} element={<CustomerInfo />} />
            <Route
              path={APP_ROUTE.editCustomerBaseInfo}
              element={<EditCustomerBaseInfo />}
            />
          </Route>
          <Route path={APP_ROUTE.subscriptions} element={<Subscriptions />} />
        </Route>
        <Route path={APP_ROUTE.noPermissions} element={<NoPermissions />} />
      </Route>
      <Route
        path={APP_ROUTE.unRegistered}
        element={<UnregisteredRouteRedirect />}
      />
    </Routes>
  );
};

export default RoutesList;
