import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';
import BackToLink from 'components/BackToLink';
import { Logo } from 'assets/vectors';

export const ContentCardWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '570px',
  minHeight: '716px',
  padding: '100px 100px 30px',
  background: COLORS.white,
  boxShadow: '0px 2px 50px 26px rgba(0, 0, 0, 0.03)',
  borderRadius: '32px',
  marginBottom: '80px',
  '@media (max-width: 1024px)': {
    padding: '80px 75px 30px',
    marginBottom: '25px',
    minHeight: '660px',
  }
});

export const StyledBackToLink = styled(BackToLink)({
  margin: '0 auto 24px',
  fontSize: '16px',
  fontWeight: 600,
});

export const StyledLogo = styled(Logo)({
  margin: '0 auto 24px',
});

export const TitleWrapper = styled('div')({
  marginBottom: '48px',
  textAlign: 'center',
});

export const Title = styled('h1')({
  marginBottom: '16px',
  fontWeight: 600,
  fontSize: '32px',
  color: COLORS.gray800,
});

export const SubTitle = styled('h2')({
  marginBottom: '10px',
  fontWeight: 500,
  fontSize: '18px',
  color: COLORS.gray600,
  whiteSpace: 'pre-line',
});

export const ChildrenWrapper = styled('div')({
  flex: '1 1 auto',
  marginBottom: '44px',
  '@media (max-width: 1024px)': {
    flex: '0 0 auto',
    marginBottom: 0,
  }
});

export const DontHaveAccountMessage = styled('p')({
  marginBottom: '20px',
  fontWeight: 500,
  fontSize: '16px',
  color: COLORS.gray900,
  textAlign: 'center',
  '@media (max-width: 1024px)': {
    marginBottom: 0,
  }
});
