import { FC } from 'react';

import { useDebounce } from 'hooks';

import { IS_COLLAPSED_DEBOUNCE_MS } from './constants';
import CollapseButtonTooltip from './CollapseButtonTooltip';
import { TCollapseButtonTooltipContainerProps } from './types';

const CollapseButtonTooltipContainer: FC<
  TCollapseButtonTooltipContainerProps
> = ({ isCollapsed }) => {
  const debouncedIsCollapsed = useDebounce(
    isCollapsed,
    IS_COLLAPSED_DEBOUNCE_MS,
  );

  return <CollapseButtonTooltip debouncedIsCollapsed={debouncedIsCollapsed} />;
};

export default CollapseButtonTooltipContainer;
