export const COLORS = {
  primary50: '#fffce0',
  primary100: '#fff9cc',
  primary200: '#fff299',
  primary300: '#ffe966',
  primary400: '#ffe03f',
  primary500: '#ffd200',
  primary600: '#dbb000',
  primary700: '#b79000',
  primary800: '#937100',
  primary900: '#7a5b00',
  red50: '#ffede5',
  red100: '#fdd9cb',
  red200: '#fcaa97',
  red300: '#f87163',
  red400: '#f13c3c',
  red500: '#e90015',
  red600: '#c80024',
  red700: '#a7002d',
  red800: '#870031',
  red900: '#6f0032',
  green50: '#f5fdeb',
  green100: '#effde1',
  green200: '#dcfcc4',
  green300: '#c3f8a5',
  green400: '#a9f28c',
  green500: '#83ea67',
  green600: '#5cc94b',
  green700: '#3ba833',
  green800: '#208721',
  green900: '#13701b',
  blue50: '#eaf9fe',
  blue100: '#d5f2fe',
  blue200: '#ace1fe',
  blue300: '#82cbfc',
  blue400: '#63b5fa',
  blue500: '#3093f8',
  blue600: '#2372d5',
  blue700: '#1855b2',
  blue800: '#0f3b8f',
  blue900: '#092977',
  orange50: '#fff8e0',
  orange100: '#fff1cc',
  orange200: '#ffde99',
  orange300: '#ffc766',
  orange400: '#ffb13f',
  orange500: '#ff8c00',
  orange600: '#db6e00',
  orange700: '#b75400',
  orange800: '#933d00',
  orange900: '#7a2c00',
  purple50: '#fce8f3',
  purple100: '#fcd1ec',
  purple200: '#f9a4e1',
  purple300: '#ed73d6',
  purple400: '#db4fcd',
  purple500: '#c41dc2',
  purple600: '#9d15a8',
  purple700: '#780e8d',
  purple800: '#570971',
  purple900: '#40055e',
  gray50: '#f9f9f9',
  gray100: '#f2f2f2',
  gray200: '#e8e8e8',
  gray300: '#d8d8d8',
  gray400: '#b4b4b4',
  gray500: '#949494',
  gray600: '#6c6c6c',
  gray700: '#595959',
  gray800: '#3a3a3a',
  gray900: '#1a1a1a',
  white: '#fff',
};
