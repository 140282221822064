import { FC, useEffect, useRef, useState } from 'react';

import CroppedTooltip from './CroppedTooltip';
import { checkIsSpanEllipsised } from './utils';
import { TCroppedTooltipContainerProps } from './types';

const CroppedTooltipContainer: FC<TCroppedTooltipContainerProps> = ({
  children,
  ...props
}) => {
  const [isValueCropped, setIsValueCropped] = useState<boolean>(false);

  const childrenRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      setIsValueCropped(checkIsSpanEllipsised(childrenRef));
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [children]);

  return (
    <CroppedTooltip ref={childrenRef} isCropped={isValueCropped} {...props}>
      {children}
    </CroppedTooltip>
  );
};

export default CroppedTooltipContainer;
