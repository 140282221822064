import io from 'socket.io-client';

import { GenerationStatus } from 'types/certificates';
import { openNotificationWithIcon } from 'utils/showNotification';
import { NotificationType } from 'types/notifications';

import { SERVER_URL } from './constants';
import { TShowNotificationResponse } from './types';

const generateSuccessNotificationMessage = (certificatesAmount: number) => (
  <>
    <span>{certificatesAmount} new certificate(s)</span> were successfully
    generated.
  </>
);

const generateErrorNotificationMessage = (certificatesAmount: number) => (
  <>
    Failed to generate
    <span>&nbsp;{certificatesAmount} new certificate(s).</span> The generation
    should be initiated again if needed.
  </>
);

export const handleShowNotification = ({
  status,
  certificatesAmount,
  errorMessage,
}: TShowNotificationResponse) => {
  if (status === GenerationStatus.ENDED && certificatesAmount) {
    openNotificationWithIcon(
      NotificationType.success,
      generateSuccessNotificationMessage(certificatesAmount),
    );
  }
  if (status === GenerationStatus.FAILED && certificatesAmount) {
    openNotificationWithIcon(
      NotificationType.error,
      errorMessage || generateErrorNotificationMessage(certificatesAmount),
    );
  }
};

const socket = io(SERVER_URL);

export default socket;
