import { useEffect } from 'react';

export const useScrollToTopOnPaginationClick = (page: number) => {
  useEffect(() => {
    const scrollContainer = document.querySelector('.ant-layout-content');
    scrollContainer?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [page]);
};
