import styled from 'styled-components';

import { DefaultInput } from 'components/Inputs';
import Select from 'components/Select';

export const PhoneNumberInputWrapper = styled('div')({
  display: 'flex',
  gap: '16px',
});

export const StyledSelect = styled(Select)({
  width: '98px',
});

export const StyledDefaultInput = styled(DefaultInput)({
  width: '100%',
});
