import styled from 'styled-components';

export const ArabicNumbersWrapper = styled('p')({
  margin: 0,
  fontFamily: 'NotoSansArabic, sans-serif',
});

export const BanknotesWrapper = styled('div')({
  display: 'flex',
  gap: '16px',
});

export const Text = styled('p')({
  marginBottom: 0,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  wordBreak: 'keep-all',
});

export const SubscriptionStatusWrapper = styled('div')({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
});

export const StyledLink = styled('a')({
  color: 'inherit',
  textDecoration: 'underline',
  '&:hover': {
    color: 'inherit',
    textDecoration: 'underline',
  },
});
