import { FC } from 'react';

import { useGoBack } from 'hooks';

import { TBackToLinkContainerProps } from './types';
import BackToLink from './BackToLink';

const BackToLinkContainer: FC<TBackToLinkContainerProps> = ({
  to,
  allowBack = true,
  ...props
}) => {
  const [_, handleGoBack, backPath] = useGoBack(to, allowBack);

  return (
    <BackToLink
      to={backPath}
      allowBack={allowBack}
      handleGoBack={handleGoBack}
      {...props}
    />
  );
};

export default BackToLinkContainer;
