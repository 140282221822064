import { FC, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import { Loader } from 'components/Loader';
import NoInternet from 'pages/NoInternet';

import { IAuthRedirectRouteProps } from './types';

const AuthRedirectRoute: FC<IAuthRedirectRouteProps> = ({
  authCheckType,
  redirectPath,
  isAuthorized,
}) => {
  const isAllowed =
    authCheckType === 'authorized' ? isAuthorized : !isAuthorized;

  return isAllowed ? (
    <ErrorBoundary fallback={<NoInternet />}>
      <Suspense fallback={<Loader />}>
        <Outlet />
      </Suspense>
    </ErrorBoundary>
  ) : (
    <Navigate replace to={redirectPath} />
  );
};

export default AuthRedirectRoute;
