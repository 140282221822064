import { useState, useEffect, useCallback } from 'react';

import { EMPTY_STRING } from 'constants/common';
import { formatTimeLeft } from 'utils/format';

import {
  COUNTDOWN_INTERVAL_MS,
  DEFAULT_COUNTDOWN_DURATION_MS,
  INITIAL_INTERVAL_VALUE,
  ZERO_SECONDS,
} from './constants';
import { IUseCountdown } from './types';

export const useCountdown = (
  delay: number = DEFAULT_COUNTDOWN_DURATION_MS,
): IUseCountdown => {
  const [timer, setTimer] = useState<number | ReturnType<typeof setInterval>>(
    INITIAL_INTERVAL_VALUE,
  );
  const [timeLeft, setTimeLeft] = useState<string>(EMPTY_STRING);

  useEffect(() => {
    return () => clearInterval(timer);
  }, []);

  const reset = useCallback(() => {
    const deadline = new Date().getTime() + delay;
    const currentTime = new Date().getTime();

    if (timer) {
      return;
    }

    const resendTimer = setCountdownTimer(deadline);

    setTimer(resendTimer);
    setTimeLeft(formatTimeLeft(deadline - currentTime));
  }, [timer, delay]);

  const setCountdownTimer = (deadline: number) => {
    const countdownTimer = setInterval(() => {
      const timeRemaining =
        deadline - new Date().getTime() + COUNTDOWN_INTERVAL_MS;

      if (timeRemaining > ZERO_SECONDS) {
        setTimeLeft(formatTimeLeft(timeRemaining));
      } else {
        setTimer(INITIAL_INTERVAL_VALUE);
        setTimeLeft(EMPTY_STRING);
        clearInterval(countdownTimer);
      }
    }, COUNTDOWN_INTERVAL_MS);

    return countdownTimer;
  };

  return { timeLeft, reset };
};
