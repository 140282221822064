import { Modal } from 'antd';

import { DEFAULT_CONFIRM_OPTIONS } from './constants';
import {
  ConfirmModalIcon,
  StyledConfirmContent,
  StyledConfirmTitle,
} from './styled';
import { IShowModalConfirmProps } from './types';

const { confirm } = Modal;

export const showConfirmModal = ({
  titleText,
  contentText,
  ...props
}: IShowModalConfirmProps) =>
  confirm({
    icon: <ConfirmModalIcon />,
    title: <StyledConfirmTitle>{titleText}</StyledConfirmTitle>,
    content: <StyledConfirmContent>{contentText}</StyledConfirmContent>,
    ...DEFAULT_CONFIRM_OPTIONS,
    ...props,
  });
