export const API_BASE = '/api';

export enum ApiRoutes {
  SIGN_IN = '/auth/sign-in/:userType',
  SIGN_OUT = '/auth/sign-out',
  USER_PROFILE = '/auth/user-profile',
  UPDATE_EMPLOYEE_PROFILE = '/employees/update-employee-profile',
  FORGOT_PASSWORD = 'auth/forgot-password/:userType',
  RESET_PASSWORD = '/auth/reset-password',
  DEALERS = '/dealers',
  GET_DEALERS_SELECT_LIST = '/dealers/dealers-select-list',
  CURRENCY_REQUESTS = '/requests',
  GET_REQUEST_PRE_COUNT = '/requests/total-count',
  MERGE_REQUESTS = '/requests/merge',
  CURRENCIES_FOR_REQUEST = '/requests/:id/currencies',
  SYNC_CURRENCY_REQUESTS = '/requests/sync',
  DELETE_CURRENCY_REQUEST = '/requests/:id',
  GET_REQUEST_DETAILS = '/requests/:id',
  GET_REQUEST_INVOICE = '/requests/:id/invoice',
  UPDATE_REQUEST_DETAILS = '/requests/:id/details',
  CHECK_EMAIL_FROM_REQUEST_WILL_BE_SENT = '/requests/:id/check-email-will-be-sent',
  RESOURCES_DENOMINATIONS = '/resources/denominations',
  RESOURCES_SERIES = '/resources/series',
  RESOURCES_MACHINES = '/resources/machines',
  GENERATE_CERTIFICATES = '/certificates/generate-certificates',
  CERTIFICATES = '/certificates',
  DELETE_CERTIFICATE = '/certificates/:id',
  SERIAL_NUMBERS = '/serial-numbers',
  CHECK_SN_DELETE_AVAILABLE = '/serial-numbers/:serialNumber/check-delete-available',
  GET_SERIAL_NUMBER_UNLOCK_STATUS = '/serial-numbers/:id/unlock-status',
  SERIAL_NUMBER_DETAILS = '/serial-numbers/:id',
  SERIAL_NUMBERS_CARDS = '/serial-numbers/aggregated',
  SERIAL_NUMBER_CERTIFICATE = '/serial-numbers/:id/certificate',
  GET_SERIAL_NUMBERS_PRE_COUNT = '/serial-numbers/total-count',
  ARCHIVE_SERIAL_NUMBER = '/serial-numbers/:id/archive',
  GENERATE_EXPORT_PDF = '/serial-numbers/generate-pdf/:id',
  RESTRICT_SERIAL_NUMBER_ACCESS = '/serial-numbers/:id/restrict-access',
  ALLOW_SERIAL_NUMBER_ACCESS = '/serial-numbers/:id/allow-access',
  LOCK_SUBSCRIPTION = '/serial-numbers/:id/lock',
  UNARCHIVE_SERIAL_NUMBER = '/serial-numbers/:id/unarchive',
  DELETE_SERIAL_NUMBER = '/serial-numbers/:serialNumberId',
  GET_SN_ANALYTICS_PRE_COUNT = '/serial-numbers/analytics/pre-count',
  SEND_UNLOCK_EMAIL = '/serial-numbers/:id/send-unlock-email',
  GET_CUSTOMERS_CURRENCIES_COUNT = '/customers/:id/count/:type',
  GET_CUSTOMERS_LIST = '/customers',
  GET_CUSOTOMERS_SELECT_LIST = '/customers/select-list',
  GET_CUSTOMER_INFO = '/customers/:id',
  GET_CUSTOMER_BASE_INFO = '/customers/:id/base-info',
  UPDATE_CUSTOMER_BASE_INFO = '/customers/:id/base-info',
  GET_CUSTOMERS_PRE_COUNT = '/customers/total-count',
  ARCHIVE_CUSTOMER = '/customers/:id/archive',
  UNARCHIVE_CUSTOMER = '/customers/:id/unarchive',
  SEND_CUSTOMER_CREDENTIALS = '/customers/:id/send-credentials',
  DELETE_CUSTOMER = '/customers/:id',
  DELETE_ARCHIVED_CUSTOMERS = '/customers/archived',
  ATTACH_SERIAL_NUMBER = '/customers/:id/serial-number',
  GET_EMPLOYEES_LIST = '/employees',
  GET_EMPLOYEE_DETAILS = '/employees/:id',
  ARCHIVE_EMPLOYEE = '/employees/:id/archive',
  UNARCHIVE_EMPLOYEE = '/employees/:id/unarchive',
  DELETE_EMPLOYEE = '/employees/:id',
  SEND_EMPLOYEE_CREDENTIALS = '/employees/:id/send-credentials',
  DELETE_ARCHIVED_EMPLOYEES = '/employees/archived',
  GET_EMPLOYEE_PRE_COUNT = '/employees/total-count',
  SUBSCRIPTIONS = '/subscriptions',
  GET_SUBSCRIPTION_PLANS = '/subscriptions/plans',
  GET_INVOICE_URL = '/subscriptions/invoice/:id',
  REFUND_SUBSCRIPTION_INVOICE = '/subscriptions/invoice/:id',
  GET_INVOICES_LIST = '/subscriptions/payments',
  GET_CURRENCY_ANALYTICS = '/analytics/currencies',
  GET_DEALERS_ANALYTICS = '/analytics/dealers',
  GET_STATES_ANALYTICS = '/analytics/states',
  GET_CURRENCY_ANALYTICS_TOTAL = '/analytics/currencies/totals',
  GET_TOTAL_ANALYTICS = '/analytics/total/totals',
  GET_STATES_ANALYTICS_TOTAL = '/analytics/states/totals',
  GET_ANALYTICS_PRE_COUNT_BY_TYPE = '/analytics/:type/total-count',
  GET_SAVE_AND_SHARE_ACCESS = '/save-and-share',
  UPDATE_SAVE_AND_SHARE_ACCESS = '/save-and-share',
  VERIFY_CODE = '/auth/verify-code',
  SEND_VERIFICATION_CODE = '/auth/send-verification-code/:userType',
  CREATE_SUBSCRIPTION = '/subscriptions/for-customer',
  GET_CUSTOMER_PAYMENT_AND_BILLING_ADDRESS = '/customers/payment-billing-address/:id',
  ADD_CUSTOMER_PAYMENT = '/subscriptions/:customerId/payment-source',
  ADD_CUSTOMER_PAYMENT_AND_BILLING_ADDRESS = '/subscriptions/:customerId/billing-details',
  UPDATE_CUSTOMER_BILLING_ADDRESS = '/subscriptions/:customerId/billing-address',
}
