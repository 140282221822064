import { FC } from 'react';

import Badge from '../Badge';
import { StyledGreenCheckIcon } from '../Badge/styled';

const VerifiedCustomerBadge: FC = () => (
  <Badge
    type='success-light'
    title='Verified'
    icon={<StyledGreenCheckIcon />}
  />
);

export default VerifiedCustomerBadge;
