import styled from 'styled-components';
import Icon from '@ant-design/icons';

import { COLORS } from 'constants/colorPalette';
import Button from 'components/Button';

import { INotFoundHolder } from './types';

export const PageWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100%',
});

export const NotFoundHolder = styled('div')<INotFoundHolder>(
  ({ isNoInternetPage }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: isNoInternetPage ? '671px' : '604px',
    height: isNoInternetPage ? '671px' : '604px',
    textAlign: 'center',
    whiteSpace: 'pre-line',
    borderRadius: '50%',
    backgroundColor: COLORS.gray100,
  }),
);

export const ErrorCode = styled('span')({
  fontSize: '128px',
  fontWeight: 600,
  color: COLORS.blue500,
});

export const ErrorTitle = styled('h2')({
  maxWidth: '492px',
  marginTop: '4px',
  fontSize: '32px',
  fontWeight: 600,
  color: COLORS.gray900,
});

export const ErrorSubtitle = styled('h3')<INotFoundHolder>(
  ({ isNoInternetPage }) => ({
    maxWidth: isNoInternetPage ? '590px' : '398px',
    marginTop: '8px',
    fontSize: '18px',
    fontWeight: 500,
    color: COLORS.gray800,
  }),
);

export const ActionButton = styled(Button)({
  minWidth: '148px',
  marginTop: '40px',
});

export const StyledIcon = styled(Icon)({
  '& svg': {
    width: '175px',
    height: '175px',
  },
});
