import { cleanEnv, url, str } from 'envalid';

export const env = cleanEnv(process.env, {
  REACT_APP_API_URL: url(),
  REACT_APP_NODE_ENV: str({
    choices: ['development', 'test', 'staging', 'production'],
  }),
  REACT_APP_TYPE: str({
    choices: ['admin'],
  }),
  REACT_APP_CB_SITE: str(),
  REACT_APP_CB_TOKEN: str(),
});
