import { MutableRefObject } from 'react';

export const checkIsSpanEllipsised = (
  ref: MutableRefObject<HTMLElement | null>,
) => {
  if (!ref) {
    return false;
  }

  return Boolean(
    ref.current?.offsetWidth &&
      ref.current?.offsetWidth < ref.current?.scrollWidth,
  );
};
