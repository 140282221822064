import { useMutation, UseMutationResult } from 'react-query';

import { apiClient } from 'api/base';
import { ApiRoutes } from 'api/routes';
import { TAxiosRequestError } from 'api/types';
import { transformRoute } from 'utils/routing';

import { IUseVerifyCodePayload } from './types';

const useVerifyCode = (
  onSuccess?: () => void,
  onError?: (error: TAxiosRequestError) => void,
): UseMutationResult<void, TAxiosRequestError, IUseVerifyCodePayload> =>
  useMutation(
    async (verifyCodePayload: IUseVerifyCodePayload) =>
      await apiClient.post(
        transformRoute(ApiRoutes.VERIFY_CODE),
        verifyCodePayload,
      ),
    {
      onSuccess,
      onError,
    },
  );

export default useVerifyCode;
