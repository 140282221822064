import {
  EMPTY_STRING,
  EMPTY_STRING_WITH_WHITESPACE,
  EMPTY_ARRAY,
  NULL_INDEX,
} from 'constants/common';

import { MAX_WORDS_NAME, GET_FIRST_LETTER_WORD } from './constants';

export const getUserInitialsFromName = (name: string) => {
  const slicedName = name
    .split(EMPTY_STRING_WITH_WHITESPACE)
    .slice(NULL_INDEX, MAX_WORDS_NAME)
    .join(EMPTY_STRING_WITH_WHITESPACE);

  const firstLettersOfName =
    slicedName.match(GET_FIRST_LETTER_WORD) || EMPTY_ARRAY;

  return firstLettersOfName.join(EMPTY_STRING).toUpperCase();
};
