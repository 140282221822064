export enum SearchParams {
  tab = 'tab',
  email = 'email',
  search = 'search',
  sortField = 'sortField',
  sortOrder = 'sortOrder',
  page = 'page',
  machine = 'machine',
  currency = 'currency',
  denom = 'denom',
  createdDateRange = 'createdDateRange',
  createdStart = 'createdStart',
  createdEnd = 'createdEnd',
  populatedStart = 'populatedStart',
  populatedEnd = 'populatedEnd',
  status = 'status',
  duration = 'duration',
  expireDateRange = 'expireDateRange',
  expireStart = 'expireStart',
  expireEnd = 'expireEnd',
  plan = 'plan',
  subscriptionsTab = 'subscriptionsTab',
  serialNumbersTab = 'serialNumbersTab',
  subscriptionsPage = 'subscriptionsPage',
  subscriptionsSearch = 'subscriptionsSearch',
  subscriptionsDenom = 'subscriptionsDenom',
  subscriptionsSortField = 'subscriptionsSortField',
  subscriptionsSortOrder = 'subscriptionsSortOrder',
  snState = 'snState',
  subscription = 'subscription',
  dealer = 'dealer',
  statusBySub = 'statusBySub',
}

export type TURLSearchParams = Partial<Record<SearchParams, string>>;

export type TSearchParamsPairs = [string, string?][];
