import { FC } from 'react';

import HeaderTitleProvider from 'components/HeaderTitleProvider';
import AppContextProvider from 'components/AppContextProvider';

import MainLayout from './components/MainLayout';

const AuthorizedLayout: FC = () => (
  <AppContextProvider>
    <HeaderTitleProvider>
      <MainLayout />
    </HeaderTitleProvider>
  </AppContextProvider>
);

export default AuthorizedLayout;
