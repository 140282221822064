import { ICustomerInfo } from './customer';
import { TPhoneNumberCountryCode } from './phoneNumbers';
import { SerialNumberStatus } from './serialNumber';

export interface ISubscription {
  _id: string;
  chargebeeId: string;
  status: TSubscriptionStatus;
  user: ICustomerInfo;
  createdAt: Date;
  updatedAt: Date;
  SNref: TSNRef;
  serialNumber: string;
  currency: string;
  denom: number;
  amount: number;
  currentTermStart: Date;
  currentTermEnd: Date;
  price: number;
  planId: string;
  planName: string;
  payments: IPaymentRecord[];
  isRefunded: boolean;
  refundedAt?: Date;
  actions?: string;
  nextBillingAt?: Date;
  cancelReason?: TCancelReason;
}

export interface ISubscriptionWithActions extends ISubscription {
  handleViewAllInvoices?: () => void;
}

export interface IPaymentRecord {
  invoiceId: string;
  invoiceName: string;
  generatedAt: Date;
  status: TPaymentStatus;
  planId: string;
  planName: string;
  planPrice: number;
  paid: number;
  isRefunded: boolean;
}

export type TSubscriptionStatus =
  | 'future'
  | 'in_trial'
  | 'active'
  | 'non_renewing'
  | 'paused'
  | 'cancelled';

export type TCancelReason =
  | 'not_paid'
  | 'no_card'
  | 'fraud_review_failed'
  | 'non_compliant_eu_customer'
  | 'tax_calculation_failed'
  | 'currency_incompatible_with_gateway'
  | 'non_compliant_customer';

export type TPaymentStatus =
  | 'paid'
  | 'posted'
  | 'payment_due'
  | 'not_paid'
  | 'voided'
  | 'pending';

export type TPaymentStatusName =
  | 'Paid'
  | 'Posted'
  | 'Payment due'
  | 'Not paid'
  | 'Voided'
  | 'Pending';

export type TPeriodUnit = 'day' | 'week' | 'month' | 'year';

export interface ISubscriptionPlan {
  id: string;
  name: string;
  price: number;
  pricePerMonth: number;
  pricePerYear: number;
  period: TPeriodUnit;
  isTrial?: boolean;
}

export interface IPaymentCheckout {
  id: string;
  url: string;
}

export type TSNRef = {
  _id: string;
  status: SerialNumberStatus;
  subscriptionStatus: TSubscriptionStatus;
  isArchived: boolean;
};

export interface ISubscriptionPayments {
  chargebeeId: string;
  subscriptionStatus: TSubscriptionStatus;
  payments: IPaymentView[];
}

export interface IPaymentView extends IPaymentRecord {
  statusName: TPaymentStatusName;
}

export enum SubscriptionStatus {
  active = 'active',
  trial = 'in_trial',
  cancelled = 'cancelled',
}

export interface IBillingAddress {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  countryCode: TPhoneNumberCountryCode;
  address: string;
  isSameAsHome?: boolean;
  company?: string;
}

export type TPaymentSource = {
  paymentSourceToken: string;
};

export enum PaymentCardRole {
  primary = 'primary',
  backup = 'backup',
  none = 'none',
}

export interface IPaymentCard {
  sourceId: string;
  expiryMonth: number;
  expiryYear: number;
  maskedNumber: string;
  brand: TPaymentCardBrand;
  role: PaymentCardRole;
}

export type TPaymentCardBrand =
  | 'visa'
  | 'mastercard'
  | 'discover'
  | 'american_express';

export type TPaymentCardBrandLookups<T = string> = {
  [key in TPaymentCardBrand]: T;
};
