import {
  MAX_SINGLE_DIGIT_NUMBER,
  MINUTE_IN_HOUR_QUANTITY,
  MS_IN_SEC_QUANTITY,
  SEC_IN_MINUTE_QUANTITY,
  ZERO_PREFIX,
} from './constants';

export const formatTimeLeft = (countDown: number) => {
  const minutes = Math.floor(
    (countDown %
      (MS_IN_SEC_QUANTITY * MINUTE_IN_HOUR_QUANTITY * SEC_IN_MINUTE_QUANTITY)) /
      (MS_IN_SEC_QUANTITY * SEC_IN_MINUTE_QUANTITY),
  );
  const seconds = Math.floor(
    (countDown % (MS_IN_SEC_QUANTITY * SEC_IN_MINUTE_QUANTITY)) /
      MS_IN_SEC_QUANTITY,
  );

  const formattedMinutes =
    minutes <= MAX_SINGLE_DIGIT_NUMBER ? ZERO_PREFIX + minutes : minutes;
  const formattedSeconds =
    seconds <= MAX_SINGLE_DIGIT_NUMBER ? ZERO_PREFIX + seconds : seconds;

  return `${formattedMinutes}:${formattedSeconds}`;
};
