import { FC, Key, useState, useEffect } from 'react';

import { AppContext } from 'contexts';
import { EMPTY_ARRAY } from 'constants/common';
import { SHOW_NOTIFICATION_EVENT_NAME } from 'components/AppContextProvider/constants';
import { TShowNotificationResponse } from 'components/AppContextProvider/types';
import { handleShowNotification } from 'components/AppContextProvider/utils';

import { IAppContextProvider } from './types';
import socket from './utils';

const AppContextProvider: FC<IAppContextProvider> = ({ children }) => {
  const [highlightedCertificates, setHighlightedCertificates] =
    useState<Key[]>(EMPTY_ARRAY);

  useEffect(() => {
    socket?.on(
      SHOW_NOTIFICATION_EVENT_NAME,
      (showNotification: TShowNotificationResponse) =>
        handleShowNotification(showNotification),
    );
    return () => {
      socket?.off(SHOW_NOTIFICATION_EVENT_NAME);
    };
  }, []);

  return (
    <AppContext.Provider
      value={{
        socket,
        highlightedCertificates,
        setHighlightedCertificates,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
