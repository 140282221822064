import { FC } from 'react';

import { useGetUserProfile } from 'api/auth';

import AuthCheckRoute from './AuthCheckRoute';

const AuthCheckRouteContainer: FC = () => {
  const {
    data: userProfileResponse,
    isLoading: isUserProfileLoading,
    isFetching: isUserProfileFetching,
  } = useGetUserProfile();

  const isAuthorized = Boolean(userProfileResponse?._id);

  return (
    <AuthCheckRoute
      isAuthorized={isAuthorized}
      isCheckingAuthorization={isUserProfileLoading || isUserProfileFetching}
    />
  );
};

export default AuthCheckRouteContainer;
