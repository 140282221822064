import { FC } from 'react';

import { RIGHTS_MESSAGE } from '../constants';
import { FooterTip, FooterWrapper } from './styled';

const Footer: FC = () => (
  <FooterWrapper>
    <FooterTip>{RIGHTS_MESSAGE}</FooterTip>
  </FooterWrapper>
);

export default Footer;
