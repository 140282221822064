import { FC } from 'react';

import { MENU_TITLE } from 'constants/menu';
import TechnicalMessage from 'pages/TechnicalMessage';

import { NO_PERMISSIONS_TEXT } from './constants';

const NoPermissions: FC = () => (
  <TechnicalMessage
    defaultPageTitle={MENU_TITLE.noPermissions}
    title={NO_PERMISSIONS_TEXT.CONTENT_TITLE}
    subTitle={NO_PERMISSIONS_TEXT.CONTENT_SUBTITLE}
  />
);

export default NoPermissions;
