import { FC } from 'react';

import { useGetUserFromQueryContext } from 'hooks';

import AuthRedirectRoute from './AuthRedirectRoute';
import { IAuthRedirectRouteContainerProps } from './types';

const AuthRedirectRouteContainer: FC<IAuthRedirectRouteContainerProps> = (
  props,
) => {
  const userProfile = useGetUserFromQueryContext();

  const isAuthorized = Boolean(userProfile?._id);

  return <AuthRedirectRoute isAuthorized={isAuthorized} {...props} />;
};

export default AuthRedirectRouteContainer;
