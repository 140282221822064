import {
  DASH_STRING,
  EMPTY_STRING,
  DEFAULT_PHONE_COUNTRY_CODE,
} from 'constants/common';
import { formatToNumbersOnly } from 'utils/format';

import { COUNTRY_CODE_VALUE } from './constants';
import { TFormatPhoneNumber } from './types';

export const formatPhoneNumber = ({
  value = EMPTY_STRING,
  countryCode = DEFAULT_PHONE_COUNTRY_CODE,
  formatType = 'withCountryCode',
}: TFormatPhoneNumber): string => {
  if (formatType === 'sliceCountryCode') {
    return value.slice(
      COUNTRY_CODE_VALUE[countryCode || DEFAULT_PHONE_COUNTRY_CODE].length + 1,
    );
  }

  const replacedNumber = formatToNumbersOnly(value);

  let formattedNumber = replacedNumber;

  switch (countryCode) {
    case 'US':
    case 'CA': {
      formattedNumber = replacedNumber.replace(
        /(\d{3})(\d{3})(\d{0,4})/,
        '($1) $2-$3',
      );

      break;
    }
    case 'GB': {
      formattedNumber = replacedNumber.replace(/(\d{4})?(\d{0,6})?/, '$1 $2');

      break;
    }
    case 'AU': {
      formattedNumber = replacedNumber.replace(
        /(\d{2})(\d{4})?(\d{0,4})?/,
        '($1) $2 $3',
      );

      break;
    }
    case 'NZ': {
      formattedNumber = replacedNumber.replace(
        /(\d{3})(\d{3})?(\d{0,4})?/,
        '$1-$2-$3',
      );

      break;
    }

    default: {
      break;
    }
  }

  switch (formatType) {
    case 'withoutCountryCode': {
      return formattedNumber;
    }

    case 'withCountryCode': {
      return `${
        COUNTRY_CODE_VALUE[countryCode || DEFAULT_PHONE_COUNTRY_CODE]
      } ${formattedNumber}`;
    }

    default: {
      return formattedNumber;
    }
  }
};

export const formatEmptyPhoneValue = ({
  value,
  ...props
}: TFormatPhoneNumber) =>
  value ? formatPhoneNumber({ value, ...props }) : DASH_STRING;
