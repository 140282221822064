import { FC } from 'react';

import { StyledInput } from './styled';
import { TSearchInputProps } from './types';
import { MAX_SEARCH_LENGTH } from '../constants';

const SearchInput: FC<TSearchInputProps> = (props) => (
  <StyledInput allowClear maxLength={MAX_SEARCH_LENGTH} {...props} />
);

export default SearchInput;
