import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';

export const LayoutWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: '100vh',
  backgroundColor: COLORS.gray50,
});

export const ContentWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: '1 1 auto',
  paddingTop: '50px',
  '@media (max-width: 1024px)': {
    paddingTop: '30px',
  }
});
