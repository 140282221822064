export const getSessionStorageValue = <T>(key: string, defaultValue: T): T => {
  const localStorageValue = sessionStorage.getItem(key);

  let value;

  try {
    value = localStorageValue ? JSON.parse(localStorageValue) : defaultValue;
  } catch {
    value = defaultValue;
  }

  return value;
};
