import {
  RequestsIcon,
  SerialNumbersIcon,
  CertificatesIcon,
  CustomersIcon,
  EmployeesIcon,
  DealersIcon,
  AnalyticsIcon,
  SubscriptionsIcon,
  DashboardIcon,
  CurrencyAnalytics,
  DealerAnalytics,
  StateAnalytics,
  HoldingsAnalytics,
} from 'assets/vectors/menu';
import { TMenuItem, TMenuItemsList, TMenuConfig } from 'types/menu';

import { APP_ROUTE } from './appRoutes';
import { EMPTY_STRING } from './common';

export const EXPANDED_MENU_WIDTH = 245;
export const COLLAPSED_MENU_WIDTH = 80;
export const HEADER_HEIGHT = 80;
export const USER_AVATAR_SIZE = 32;

export const MENU_TITLE = {
  logo: 'Dinar Verify',
  dashboard: 'Dashboard',
  requests: 'Requests',
  newRequestDetails: 'New Request Details',
  completeRequestDetails: 'Complete Request Details',
  serialNumbers: 'Serial Numbers',
  serialNumberDetails: 'Serial Number #',
  subscriptions: 'Subscriptions',
  certificates: 'Certificates',
  customerInfo: 'Customer',
  customers: 'Customers',
  createCustomer: 'Add new customer',
  editCustomer: 'Edit Customer',
  employees: 'Employees',
  createEmployee: 'Add New Employee',
  employeeDetails: 'Employee',
  dealers: 'Dealers',
  createDealer: 'Add New Dealer',
  holdingsAnalytics: 'Analytics: Holdings',
  notFound: 'Page Not Found',
  noPermissions: 'No Permissions',
  noInternet: 'No Internet',
  signIn: 'Sign In',
  signUp: 'Sign Up',
  signOut: 'Sign Out',
  forgotPassword: 'Forgot Password',
  resetPassword: 'Reset Password',
  profile: 'Profile Details',
  analytics: 'Analytics',
};

export const ANALYTICS_SUBMENU_TITLE = {
  currencyAnalytics: 'Currencies',
  dealerAnalytics: 'Dealers',
  stateAnalytics: 'States',
  holdingsAnalytics: 'Holdings',
};

const ANALYTICS_SUBMENU_ITEMS_LIST: TMenuItem[] = [
  {
    menuTitle: ANALYTICS_SUBMENU_TITLE.currencyAnalytics,
    pathTo: APP_ROUTE.currencyAnalytics,
    icon: CurrencyAnalytics,
  },
  {
    menuTitle: ANALYTICS_SUBMENU_TITLE.dealerAnalytics,
    pathTo: APP_ROUTE.dealerAnalytics,
    icon: DealerAnalytics,
  },
  {
    menuTitle: ANALYTICS_SUBMENU_TITLE.stateAnalytics,
    pathTo: APP_ROUTE.stateAnalytics,
    icon: StateAnalytics,
  },
  {
    menuTitle: ANALYTICS_SUBMENU_TITLE.holdingsAnalytics,
    pathTo: APP_ROUTE.holdingsAnalytics,
    icon: HoldingsAnalytics,
  },
];

const MENU_ITEMS_LIST: TMenuItemsList = {
  [MENU_TITLE.dashboard]: {
    menuTitle: MENU_TITLE.dashboard,
    pathTo: APP_ROUTE.dashboard,
    icon: DashboardIcon,
  },
  [MENU_TITLE.requests]: {
    menuTitle: MENU_TITLE.requests,
    pathTo: APP_ROUTE.requests,
    icon: RequestsIcon,
  },
  [MENU_TITLE.serialNumbers]: {
    menuTitle: MENU_TITLE.serialNumbers,
    pathTo: APP_ROUTE.serialNumbers,
    icon: SerialNumbersIcon,
  },
  [MENU_TITLE.subscriptions]: {
    menuTitle: MENU_TITLE.subscriptions,
    pathTo: APP_ROUTE.subscriptions,
    icon: SubscriptionsIcon,
  },
  [MENU_TITLE.certificates]: {
    menuTitle: MENU_TITLE.certificates,
    pathTo: APP_ROUTE.certificates,
    icon: CertificatesIcon,
  },
  [MENU_TITLE.customers]: {
    menuTitle: MENU_TITLE.customers,
    pathTo: APP_ROUTE.customers,
    icon: CustomersIcon,
  },
  [MENU_TITLE.employees]: {
    menuTitle: MENU_TITLE.employees,
    pathTo: APP_ROUTE.employees,
    icon: EmployeesIcon,
  },
  [MENU_TITLE.dealers]: {
    menuTitle: MENU_TITLE.dealers,
    pathTo: APP_ROUTE.dealers,
    icon: DealersIcon,
  },
  [MENU_TITLE.analytics]: {
    menuTitle: MENU_TITLE.analytics,
    pathTo: EMPTY_STRING,
    icon: AnalyticsIcon,
    submenu: ANALYTICS_SUBMENU_ITEMS_LIST,
  },
};

export const MENU_CONFIG: TMenuConfig = {
  development: {
    super_admin: [
      MENU_ITEMS_LIST[MENU_TITLE.dashboard],
      MENU_ITEMS_LIST[MENU_TITLE.requests],
      MENU_ITEMS_LIST[MENU_TITLE.serialNumbers],
      MENU_ITEMS_LIST[MENU_TITLE.subscriptions],
      MENU_ITEMS_LIST[MENU_TITLE.certificates],
      MENU_ITEMS_LIST[MENU_TITLE.customers],
      MENU_ITEMS_LIST[MENU_TITLE.employees],
      MENU_ITEMS_LIST[MENU_TITLE.dealers],
      MENU_ITEMS_LIST[MENU_TITLE.analytics],
    ] as TMenuItem[],
    admin: [
      MENU_ITEMS_LIST[MENU_TITLE.dashboard],
      MENU_ITEMS_LIST[MENU_TITLE.requests],
      MENU_ITEMS_LIST[MENU_TITLE.serialNumbers],
      MENU_ITEMS_LIST[MENU_TITLE.subscriptions],
      MENU_ITEMS_LIST[MENU_TITLE.certificates],
      MENU_ITEMS_LIST[MENU_TITLE.customers],
      MENU_ITEMS_LIST[MENU_TITLE.employees],
      MENU_ITEMS_LIST[MENU_TITLE.dealers],
      MENU_ITEMS_LIST[MENU_TITLE.analytics],
    ] as TMenuItem[],
    manager: [
      MENU_ITEMS_LIST[MENU_TITLE.dashboard],
      MENU_ITEMS_LIST[MENU_TITLE.requests],
      MENU_ITEMS_LIST[MENU_TITLE.serialNumbers],
      MENU_ITEMS_LIST[MENU_TITLE.subscriptions],
      MENU_ITEMS_LIST[MENU_TITLE.certificates],
      MENU_ITEMS_LIST[MENU_TITLE.customers],
      MENU_ITEMS_LIST[MENU_TITLE.dealers],
      MENU_ITEMS_LIST[MENU_TITLE.analytics],
    ] as TMenuItem[],
    vault: [
      MENU_ITEMS_LIST[MENU_TITLE.requests],
      MENU_ITEMS_LIST[MENU_TITLE.serialNumbers],
    ] as TMenuItem[],
    customer_service: [
      MENU_ITEMS_LIST[MENU_TITLE.serialNumbers],
      MENU_ITEMS_LIST[MENU_TITLE.subscriptions],
      MENU_ITEMS_LIST[MENU_TITLE.customers],
    ] as TMenuItem[],
    sales: [
      MENU_ITEMS_LIST[MENU_TITLE.dashboard],
      MENU_ITEMS_LIST[MENU_TITLE.serialNumbers],
      MENU_ITEMS_LIST[MENU_TITLE.subscriptions],
      MENU_ITEMS_LIST[MENU_TITLE.customers],
      MENU_ITEMS_LIST[MENU_TITLE.analytics],
    ] as TMenuItem[],
  },
  staging: {
    super_admin: [
      MENU_ITEMS_LIST[MENU_TITLE.dashboard],
      MENU_ITEMS_LIST[MENU_TITLE.requests],
      MENU_ITEMS_LIST[MENU_TITLE.serialNumbers],
      MENU_ITEMS_LIST[MENU_TITLE.subscriptions],
      MENU_ITEMS_LIST[MENU_TITLE.certificates],
      MENU_ITEMS_LIST[MENU_TITLE.customers],
      MENU_ITEMS_LIST[MENU_TITLE.employees],
      MENU_ITEMS_LIST[MENU_TITLE.dealers],
      MENU_ITEMS_LIST[MENU_TITLE.analytics],
    ] as TMenuItem[],
    admin: [
      MENU_ITEMS_LIST[MENU_TITLE.dashboard],
      MENU_ITEMS_LIST[MENU_TITLE.requests],
      MENU_ITEMS_LIST[MENU_TITLE.serialNumbers],
      MENU_ITEMS_LIST[MENU_TITLE.subscriptions],
      MENU_ITEMS_LIST[MENU_TITLE.certificates],
      MENU_ITEMS_LIST[MENU_TITLE.customers],
      MENU_ITEMS_LIST[MENU_TITLE.employees],
      MENU_ITEMS_LIST[MENU_TITLE.dealers],
      MENU_ITEMS_LIST[MENU_TITLE.analytics],
    ] as TMenuItem[],
    manager: [
      MENU_ITEMS_LIST[MENU_TITLE.dashboard],
      MENU_ITEMS_LIST[MENU_TITLE.requests],
      MENU_ITEMS_LIST[MENU_TITLE.serialNumbers],
      MENU_ITEMS_LIST[MENU_TITLE.subscriptions],
      MENU_ITEMS_LIST[MENU_TITLE.certificates],
      MENU_ITEMS_LIST[MENU_TITLE.customers],
      MENU_ITEMS_LIST[MENU_TITLE.dealers],
      MENU_ITEMS_LIST[MENU_TITLE.analytics],
    ] as TMenuItem[],
    vault: [
      MENU_ITEMS_LIST[MENU_TITLE.requests],
      MENU_ITEMS_LIST[MENU_TITLE.serialNumbers],
    ] as TMenuItem[],
    customer_service: [
      MENU_ITEMS_LIST[MENU_TITLE.serialNumbers],
      MENU_ITEMS_LIST[MENU_TITLE.subscriptions],
      MENU_ITEMS_LIST[MENU_TITLE.customers],
    ] as TMenuItem[],
    sales: [
      MENU_ITEMS_LIST[MENU_TITLE.dashboard],
      MENU_ITEMS_LIST[MENU_TITLE.serialNumbers],
      MENU_ITEMS_LIST[MENU_TITLE.subscriptions],
      MENU_ITEMS_LIST[MENU_TITLE.customers],
      MENU_ITEMS_LIST[MENU_TITLE.analytics],
    ] as TMenuItem[],
  },
  test: {
    super_admin: [
      MENU_ITEMS_LIST[MENU_TITLE.dashboard],
      MENU_ITEMS_LIST[MENU_TITLE.requests],
      MENU_ITEMS_LIST[MENU_TITLE.serialNumbers],
      MENU_ITEMS_LIST[MENU_TITLE.subscriptions],
      MENU_ITEMS_LIST[MENU_TITLE.certificates],
      MENU_ITEMS_LIST[MENU_TITLE.customers],
      MENU_ITEMS_LIST[MENU_TITLE.employees],
      MENU_ITEMS_LIST[MENU_TITLE.dealers],
      MENU_ITEMS_LIST[MENU_TITLE.analytics],
    ] as TMenuItem[],
    admin: [
      MENU_ITEMS_LIST[MENU_TITLE.dashboard],
      MENU_ITEMS_LIST[MENU_TITLE.requests],
      MENU_ITEMS_LIST[MENU_TITLE.serialNumbers],
      MENU_ITEMS_LIST[MENU_TITLE.subscriptions],
      MENU_ITEMS_LIST[MENU_TITLE.certificates],
      MENU_ITEMS_LIST[MENU_TITLE.customers],
      MENU_ITEMS_LIST[MENU_TITLE.employees],
      MENU_ITEMS_LIST[MENU_TITLE.dealers],
      MENU_ITEMS_LIST[MENU_TITLE.analytics],
    ] as TMenuItem[],
    manager: [
      MENU_ITEMS_LIST[MENU_TITLE.dashboard],
      MENU_ITEMS_LIST[MENU_TITLE.requests],
      MENU_ITEMS_LIST[MENU_TITLE.serialNumbers],
      MENU_ITEMS_LIST[MENU_TITLE.subscriptions],
      MENU_ITEMS_LIST[MENU_TITLE.certificates],
      MENU_ITEMS_LIST[MENU_TITLE.customers],
      MENU_ITEMS_LIST[MENU_TITLE.dealers],
      MENU_ITEMS_LIST[MENU_TITLE.analytics],
    ] as TMenuItem[],
    vault: [
      MENU_ITEMS_LIST[MENU_TITLE.requests],
      MENU_ITEMS_LIST[MENU_TITLE.serialNumbers],
    ] as TMenuItem[],
    customer_service: [
      MENU_ITEMS_LIST[MENU_TITLE.serialNumbers],
      MENU_ITEMS_LIST[MENU_TITLE.subscriptions],
      MENU_ITEMS_LIST[MENU_TITLE.customers],
    ] as TMenuItem[],
    sales: [
      MENU_ITEMS_LIST[MENU_TITLE.dashboard],
      MENU_ITEMS_LIST[MENU_TITLE.serialNumbers],
      MENU_ITEMS_LIST[MENU_TITLE.subscriptions],
      MENU_ITEMS_LIST[MENU_TITLE.customers],
      MENU_ITEMS_LIST[MENU_TITLE.analytics],
    ] as TMenuItem[],
  },
};
