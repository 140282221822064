import { useEffect, useState } from 'react';

import {
  INITIAL_TIMER_VALUE,
  RESEND_LINK_TIMER_DURATION_MS,
} from 'constants/common';

export const useTimer = () => {
  const [timer, setTimer] = useState<number | ReturnType<typeof setTimeout>>(
    INITIAL_TIMER_VALUE,
  );

  const clearTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }
  };

  const setResendTimer = () => {
    clearTimer();

    setTimer(
      setTimeout(() => {
        setTimer(INITIAL_TIMER_VALUE);
      }, RESEND_LINK_TIMER_DURATION_MS),
    );
  };

  useEffect(() => {
    return () => clearTimeout(timer);
  }, [timer]);
  return { timer, setResendTimer, clearTimer };
};
