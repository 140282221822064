import { FC } from 'react';
import { Navigate } from 'react-router';

import { APP_ROUTE } from 'constants/appRoutes';
import { NotFound } from 'pages';

import { IUnregisteredRouteRedirectProps } from './types';

const UnregisteredRouteRedirect: FC<IUnregisteredRouteRedirectProps> = ({
  isAuthorized,
}) =>
  isAuthorized ? <NotFound /> : <Navigate replace to={APP_ROUTE.signIn} />;

export default UnregisteredRouteRedirect;
