import { FC } from 'react';
import { Link } from 'react-router-dom';

import { APP_ROUTE } from 'constants/appRoutes';
import { EMPTY_STRING_WITH_WHITESPACE } from 'constants/common';
import CroppedTooltip from 'components/CroppedTooltip';
import Tooltip from 'components/Tooltip';

import { BACK_LINK_TEXT, PROFILE_DETAILS_TEXT, DASH_REGEXP } from './constants';
import UserAvatar from './UserAvatar';
import {
  HeaderTitle,
  HeaderTitleWrapper,
  HeaderWrapper,
  UserInfoWrapper,
  UserName,
  StyledDivider,
  UserRole,
  BurgerButton,
  BurgerButtonShape,
  StyledBackToLink,
} from './styled';
import { generateHelloMessage } from './utils';
import { IHeaderProps } from './types';

const Header: FC<IHeaderProps> = ({
  headerTitle,
  headerSubTitle,
  backLinkPath,
  userRole,
  userFullName,
  userAvatar,
  isCollapsed,
  toggleIsMobileMenuOpened,
}) => {
  const croppedTitleValue = `${headerTitle} ${headerSubTitle}`;
  const formattedUserRole = userRole.replace(
    DASH_REGEXP,
    EMPTY_STRING_WITH_WHITESPACE,
  );

  return (
    <HeaderWrapper>
      <HeaderTitleWrapper>
        <BurgerButton onClick={toggleIsMobileMenuOpened}>
          <BurgerButtonShape />
        </BurgerButton>
        {backLinkPath && (
          <StyledBackToLink to={backLinkPath}>
            {BACK_LINK_TEXT}
          </StyledBackToLink>
        )}
        <CroppedTooltip title={croppedTitleValue} placement='bottomLeft'>
          <HeaderTitle isCollapsed={isCollapsed}>
            {headerTitle}
            {headerSubTitle && <span>&nbsp;{headerSubTitle}</span>}
          </HeaderTitle>
        </CroppedTooltip>
      </HeaderTitleWrapper>
      <UserInfoWrapper>
        <CroppedTooltip title={userFullName} placement='bottomLeft'>
          <UserName>{generateHelloMessage(userFullName)}</UserName>
        </CroppedTooltip>
        <StyledDivider type='vertical' />
        <UserRole>{formattedUserRole}</UserRole>
        <Tooltip title={PROFILE_DETAILS_TEXT} placement='bottomLeft'>
          <Link to={APP_ROUTE.profile}>
            <UserAvatar userFullName={userFullName} userAvatar={userAvatar} />
          </Link>
        </Tooltip>
      </UserInfoWrapper>
    </HeaderWrapper>
  );
};

export default Header;
