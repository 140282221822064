import { FC } from 'react';

import { REQUIRED_SYMBOL } from './constants';
import InputErrorText from './InputErrorText';
import { LabelWrapper, InputWrapper, LabelText } from './styled';
import { IInputBaseProps } from './types';

const InputBase: FC<IInputBaseProps> = ({
  required,
  disabled,
  className,
  labelText,
  placeholder,
  errorMessage,
  children,
  isError,
  isOccupied,
  isFocused,
  handleBlur,
  handleFocus,
}) => {
  const activeText = isOccupied ? labelText : placeholder || labelText;
  const activeTextWithRequired = required
    ? activeText.concat(REQUIRED_SYMBOL)
    : activeText;

  return (
    <LabelWrapper
      className={className}
      isError={isError}
      isOccupied={isOccupied}
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      <InputWrapper>
        <LabelText
          isOccupied={isOccupied}
          isFocused={isFocused}
          isError={isError}
          disabled={disabled}
        >
          {activeTextWithRequired}
        </LabelText>
        {children}
      </InputWrapper>
      {errorMessage && <InputErrorText errorMessage={errorMessage} />}
    </LabelWrapper>
  );
};

export default InputBase;
