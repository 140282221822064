import { FC } from 'react';

import {
  ContentCardWrapper,
  StyledBackToLink,
  StyledLogo,
  TitleWrapper,
  Title,
  SubTitle,
  ChildrenWrapper,
} from './styled';
import { IContentCardProps } from './types';

const ContentCard: FC<IContentCardProps> = ({
  backLinkConfig,
  title,
  subTitle,
  children,
}) => (
  <ContentCardWrapper>
    {backLinkConfig && (
      <StyledBackToLink
        allowBack={false}
        to={backLinkConfig.to}
        onClick={backLinkConfig.onClick}
      >
        {backLinkConfig.title}
      </StyledBackToLink>
    )}
    <StyledLogo />
    <TitleWrapper>
      <Title>{title}</Title>
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
    </TitleWrapper>
    <ChildrenWrapper>{children}</ChildrenWrapper>
  </ContentCardWrapper>
);

export default ContentCard;
