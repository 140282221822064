import { useRef, useLayoutEffect, useEffect, FC } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Modal } from 'antd';

import { useSignOut } from 'api/auth';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'constants/common';
import {
  useGetUserFromQueryContext,
  useToggle,
  useGetIsDesktopVersion,
  useSetAppContext,
} from 'hooks';
import { showConfirmModal } from 'utils/showConfirmModal';
import { SearchParams } from 'types/urlSearchParams';

import MainLayout from './MainLayout';
import { SIGN_OUT_CONFIRM_PROPS } from './constants';

const MainLayoutContainer: FC = () => {
  const { pathname } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams(EMPTY_OBJECT);

  const isSignOutPopConfirmShown = useRef<boolean>(true);
  const { mutate: signOutMutate } = useSignOut();

  const [isMobileMenuOpened, toggleIsMobileMenuOpened] = useToggle(false);
  const [isCollapsed, toggleIsCollapsed] = useToggle(true);

  const userProfile = useGetUserFromQueryContext();

  const isDesktopVersion = useGetIsDesktopVersion();

  const ref = useRef<HTMLDivElement>(null);

  const { setHighlightedCertificates } = useSetAppContext();

  const clearHighlighting = () => setHighlightedCertificates(EMPTY_ARRAY);

  const handleSignOutOpenedTabs = (event: StorageEvent) => {
    if (event.key === 'signOutAdmin') {
      signOutMutate();
    }
  };

  const handleSignOut = () => {
    isSignOutPopConfirmShown.current = false;

    localStorage.setItem('signOutAdmin', Date.now().toString());

    signOutMutate();
  };

  useEffect(() => {
    const emailFromLink = searchParams.get(SearchParams.email) as string;

    if (emailFromLink) {
      if (
        isSignOutPopConfirmShown.current &&
        userProfile.email !== emailFromLink
      ) {
        showConfirmModal({
          ...SIGN_OUT_CONFIRM_PROPS,
          onOk: () => handleSignOut(),
        });
      }
      setSearchParams(EMPTY_OBJECT);
    }

    window.addEventListener('storage', handleSignOutOpenedTabs);

    return () => {
      window.removeEventListener('storage', handleSignOutOpenedTabs);
    };
  }, []);

  useLayoutEffect(() => {
    if (ref.current) {
      if (!isSignOutPopConfirmShown.current) {
        Modal.destroyAll();
      }

      ref.current.scrollTo(0, 0);
    }
  }, [pathname]);

  useEffect(() => {
    const handleClearHistoryState = () => {
      window.history.replaceState(EMPTY_OBJECT, document.title);
    };

    window.addEventListener('beforeunload', handleClearHistoryState);

    return () =>
      window.removeEventListener('beforeunload', handleClearHistoryState);
  }, []);

  return (
    <MainLayout
      ref={ref}
      userProfile={userProfile}
      isCollapsed={isCollapsed && isDesktopVersion}
      isMobileMenuOpened={isMobileMenuOpened}
      toggleIsCollapsed={toggleIsCollapsed}
      toggleIsMobileMenuOpened={toggleIsMobileMenuOpened}
      clearHighlighting={clearHighlighting}
    />
  );
};

export default MainLayoutContainer;
