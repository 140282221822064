import { FC } from 'react';
import { isEmpty } from 'lodash';

import { MENU_TITLE } from 'constants/menu';
import { SERVER_ERROR_STRING } from 'constants/messages';
import { MAX_EMAIL_LENGTH } from 'constants/validations';
import { DefaultInput } from 'components/Inputs';
import HelmetPageTitle from 'components/HelmetPageTitle';
import { ALERT_TYPE } from 'components/Alert';
import { Loader } from 'components/Loader';
import { ContentCard } from 'components/UnauthorizedPages';
import { getResendButtonDisableText } from 'constants/datetime';
import { EMPTY_STRING } from 'constants/common';

import {
  FORGOT_PASSWORD_TEXT,
  BACK_TO_SIGN_IN_CONFIG,
  BACK_TO_FORGOT_IN_CONFIG,
  successResetLinkMessage,
} from './constants';
import { ForgotPasswordField, IForgotPasswordProps } from './types';
import {
  InputWrapper,
  StyledSendLinkButton,
  StyledAlert,
  ResendButtonNotAvailableText,
  ResendButtonWrapper,
  StyledResendButton,
  EmailSentText,
} from './styled';

const ForgotPassword: FC<IForgotPasswordProps> = ({
  formik,
  resendButtonTimer,
  errorMessage,
  isFormVisible,
  isSendLinkLoading,
  onBackLinkClick,
  onResendButtonClick,
}) => {
  const { values, errors, handleSubmit, getFieldProps } = formik;

  const isResetLinkErrorMessage =
    errorMessage && errorMessage.includes(SERVER_ERROR_STRING);
  const isErrorAlertShown = errorMessage && isEmpty(errors);

  return (
    <HelmetPageTitle title={MENU_TITLE.forgotPassword}>
      {isFormVisible ? (
        <ContentCard
          title={FORGOT_PASSWORD_TEXT.forgotPageTitle}
          subTitle={FORGOT_PASSWORD_TEXT.forgotPageSubTitle}
          backLinkConfig={BACK_TO_SIGN_IN_CONFIG}
        >
          {isErrorAlertShown && !isResetLinkErrorMessage && (
            <StyledAlert type={ALERT_TYPE.ERROR} message={errorMessage} />
          )}
          <form onSubmit={handleSubmit}>
            <InputWrapper>
              <DefaultInput
                labelText={FORGOT_PASSWORD_TEXT.inputLabel}
                errorMessage={errors[ForgotPasswordField.email]}
                maxLength={MAX_EMAIL_LENGTH}
                {...getFieldProps(ForgotPasswordField.email)}
              />
            </InputWrapper>
            <StyledSendLinkButton
              htmlType='submit'
              size='large'
              loading={isSendLinkLoading}
            >
              {FORGOT_PASSWORD_TEXT.sendLinkButtonText}
            </StyledSendLinkButton>
          </form>
        </ContentCard>
      ) : (
        <ContentCard
          title={FORGOT_PASSWORD_TEXT.verifyPageTitle}
          backLinkConfig={{
            ...BACK_TO_FORGOT_IN_CONFIG,
            onClick: onBackLinkClick,
          }}
        >
          {isResetLinkErrorMessage ? (
            <StyledAlert type={ALERT_TYPE.ERROR} message={errorMessage} />
          ) : (
            <EmailSentText>
              {successResetLinkMessage(values[ForgotPasswordField.email])}
            </EmailSentText>
          )}
          {isSendLinkLoading ? (
            <Loader />
          ) : resendButtonTimer !== EMPTY_STRING ? (
            <ResendButtonNotAvailableText>
              {getResendButtonDisableText(resendButtonTimer)}
            </ResendButtonNotAvailableText>
          ) : (
            <ResendButtonWrapper>
              {FORGOT_PASSWORD_TEXT.didntReceiveEmail}&nbsp;
              <StyledResendButton
                htmlType='submit'
                type='link'
                onClick={onResendButtonClick}
              >
                {FORGOT_PASSWORD_TEXT.resendButtonText}
              </StyledResendButton>
            </ResendButtonWrapper>
          )}
        </ContentCard>
      )}
    </HelmetPageTitle>
  );
};

export default ForgotPassword;
