import { MutableRefObject, useRef, useEffect } from 'react';

export const useScrollToTriggeredElement = <T,>(
  trigger: T,
): MutableRefObject<HTMLDivElement | null> => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [trigger]);

  return ref;
};
