import { useEffect, FC } from 'react';

import { useSetHeaderTitle } from 'hooks';
import { EMPTY_STRING } from 'constants/common';

import { IHelmetPageTitleContainerProps } from './types';
import HelmetPageTitle from './HelmetPageTitle';

const HelmetPageTitleContainer: FC<IHelmetPageTitleContainerProps> = ({
  title,
  headerTitle,
  headerSubTitle,
  backLinkPath,
  children,
}) => {
  const { setTitle, setSubTitle, setBackLinkPath } = useSetHeaderTitle();

  useEffect(() => {
    const headerText = headerTitle || title || EMPTY_STRING;
    const subTitle = headerSubTitle || EMPTY_STRING;
    const backToPath = backLinkPath || EMPTY_STRING;

    setTitle(headerText);
    setSubTitle(subTitle);
    setBackLinkPath(backToPath);
  }, [title, headerTitle, headerSubTitle, backLinkPath]);

  return <HelmetPageTitle title={title}>{children}</HelmetPageTitle>;
};

export default HelmetPageTitleContainer;
