import { createRoot } from 'react-dom/client';

import './index.less';
import App from './App';
import reportWebVitals from './reportWebVitals';

const rootElem = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElem);

root.render(<App />);

reportWebVitals();
