import {
  ForwardRefRenderFunction,
  forwardRef,
  isValidElement,
  cloneElement,
} from 'react';

import Tooltip from 'components/Tooltip';

import { TCroppedTooltipProps } from './types';
import CroppedTooltipTitle from './CroppedTooltipTitle';

const CroppedTooltip: ForwardRefRenderFunction<
  HTMLElement,
  TCroppedTooltipProps
> = ({ children, title, placement = 'right', isCropped, ...props }, ref) => {
  const reffedChildren = isValidElement(children)
    ? cloneElement(children as JSX.Element, { ref })
    : children;

  const hasTitle = isCropped && title;

  return (
    <Tooltip
      title={
        hasTitle && <CroppedTooltipTitle title={title as React.ReactNode} />
      }
      placement={placement}
      {...props}
    >
      {reffedChildren}
    </Tooltip>
  );
};

export default forwardRef(CroppedTooltip);
