import { FC } from 'react';

import { RoutesList } from 'components/Routes';

import Footer from './Footer';
import { LayoutWrapper, ContentWrapper } from './styled';

const UnauthorizedLayout: FC = () => (
  <LayoutWrapper>
    <ContentWrapper>
      <RoutesList />
    </ContentWrapper>
    <Footer />
  </LayoutWrapper>
);

export default UnauthorizedLayout;
