import { useEffect } from 'react';

import { NULL_INDEX, NULL_VALUE } from 'constants/common';
import { TFormikConfig } from 'types/formik';

export const useScrollToFirstFormikError = <T,>(formik: TFormikConfig<T>) => {
  const { errors, isSubmitting, isValidating } = formik;

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      const keys = Object.keys(errors);

      if (keys.length > NULL_VALUE) {
        const selector = `[id=${keys[NULL_INDEX]}]`;
        const errorElement = document.querySelector(selector) as HTMLElement;

        if (errorElement) {
          errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          errorElement.focus({ preventScroll: true });
        }
      }
    }
  }, [errors, isSubmitting, isValidating]);
};
