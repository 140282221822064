import {
  useFormik as useFormikLib,
  FormikValues as IFormikValues,
  FormikConfig as IFormikConfig,
} from 'formik';

import { useToggle } from 'hooks';

export const useFormik = <T extends IFormikValues = IFormikValues>(
  props: IFormikConfig<T>,
) => {
  const [isValidationEnabled, toggleValidationEnabled] = useToggle();

  const handleOnValidate = () => {
    if (!isValidationEnabled) {
      toggleValidationEnabled();
    }
  };

  const formik = useFormikLib({
    ...props,
    validateOnBlur: isValidationEnabled,
    validateOnChange: isValidationEnabled,
    validate: handleOnValidate,
  });

  return formik;
};
