import { APP_ROUTE } from 'constants/appRoutes';
import { EMPTY_STRING } from 'constants/common';
import { MENU_TITLE } from 'constants/menu';

const EXPECTED_ROOT_POSITION = 0;
const ROOT_PATH_LEVELS = 1;

const extractValuablePathLevels = (path: string): string[] =>
  path.split('/').filter((level) => Boolean(level));

const extractRouteRoot = (path: string): string =>
  extractValuablePathLevels(path)[EXPECTED_ROOT_POSITION] || EMPTY_STRING;

const HEADER_TITLE_LOOKUP = {
  [extractRouteRoot(APP_ROUTE.requests)]: MENU_TITLE.requests,
  [extractRouteRoot(APP_ROUTE.serialNumbers)]: MENU_TITLE.serialNumbers,
  [extractRouteRoot(APP_ROUTE.dashboard)]: MENU_TITLE.dashboard,
  [extractRouteRoot(APP_ROUTE.subscriptions)]: MENU_TITLE.subscriptions,
  [extractRouteRoot(APP_ROUTE.dealers)]: MENU_TITLE.dealers,
  [extractRouteRoot(APP_ROUTE.employees)]: MENU_TITLE.employees,
  [extractRouteRoot(APP_ROUTE.certificates)]: MENU_TITLE.certificates,
  [extractRouteRoot(APP_ROUTE.customers)]: MENU_TITLE.customers,
};

const resolvePathRootToTitle = (
  root: string,
  defaultPageTitle: string,
): string => HEADER_TITLE_LOOKUP[root] || defaultPageTitle;

const checkIsMultiLevelPath = (path: string): boolean =>
  extractValuablePathLevels(path).length > ROOT_PATH_LEVELS;

export const getRouteRootHeaderTitle = (
  path: string,
  defaultPageTitle: string,
): string => {
  const root = extractRouteRoot(path);
  let title = resolvePathRootToTitle(root, defaultPageTitle);
  if (title === MENU_TITLE.customers) {
    switch (true) {
      case /customers\/\w+\/edit\/not-found/.test(path):
        title = MENU_TITLE.editCustomer;
        break;
      case /customers\/\w+\/info\/not-found/.test(path):
        title = MENU_TITLE.customerInfo;
        break;
    }
  }
  return title;
};

export const getRouteBacklinkPath = (
  path: string,
  defaultPageTitle: string,
): string => {
  const isMultiLevelPath = checkIsMultiLevelPath(path);

  if (!isMultiLevelPath) {
    return EMPTY_STRING;
  }

  const root = extractRouteRoot(path);
  const hasBackLink =
    resolvePathRootToTitle(root, defaultPageTitle) !== defaultPageTitle;

  return hasBackLink ? root : EMPTY_STRING;
};
