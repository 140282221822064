import styled from 'styled-components';
import { Collapse } from 'antd';

import { COLORS } from 'constants/colorPalette';

const { Panel } = Collapse;

export const StyledCollapse = styled(Collapse)({
  background: 'transparent',
  '&.ant-collapse-borderless .ant-collapse-item .ant-collapse-header': {
    borderRadius: '8px',
  },
  '&.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header':
    {
      paddingLeft: '16px',
    },
  '> .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow': {
    transition: 'transform .2s',
    transform: 'rotate(180deg) translateY(50%)',
  },
  '> .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header .ant-collapse-arrow':
    {
      transform: 'rotate(360deg) translateY(-50%)',
    },
  '& .ant-collapse-content > .ant-collapse-content-box': {
    padding: '0 0 0 30px',
    transition: 'padding .2s',
  },
  '> .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-content > .ant-collapse-content-box':
    {
      padding: '4px 0 0 0',
    },
});

export const StyledPanel = styled(Panel)({
  '&.ant-collapse-item .ant-collapse-header': {
    height: '48px',
    padding: '12px 16px',
    fontWeight: 500,
    color: COLORS.gray700,
    borderRadius: '8px',
    letterSpacing: '0.2px',
    transition: 'background .2s',
    '&:hover': {
      color: COLORS.gray700,
      background: COLORS.gray50,
    },
    '& .ant-collapse-header-text': {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        width: '22px',
        height: '22px',
        fill: COLORS.gray700,
      },
    },
  },
});
