import { FC } from 'react';

import {
  EXPANDED_MENU_WIDTH,
  COLLAPSED_MENU_WIDTH,
  MENU_TITLE,
} from 'constants/menu';
import Tooltip from 'components/Tooltip';
import { LogoIcon, CollapsedLogoIcon, SignOutIcon } from 'assets/vectors/menu';

import NavigationMenu from './NavigationMenu';
import CollapseButton from './CollapseButton';
import {
  StyledSider,
  OutSideClickHandler,
  SiderContentWrapper,
  LogoWrapper,
  SignOutButton,
  StyledSignOutIcon,
  SignOutText,
  NavigationMenuWrapper,
} from './styled';
import { ISideMenuProps } from './types';

export const SideMenu: FC<ISideMenuProps> = ({
  menuItems,
  isCollapsed,
  isMobileMenuOpened,
  toggleIsMobileMenuOpened,
  handleClickSignOut,
  handleClickCollapse,
  handleMenuItemClick,
}) => (
  <>
    <StyledSider
      collapsed={isCollapsed}
      collapsedWidth={COLLAPSED_MENU_WIDTH}
      width={EXPANDED_MENU_WIDTH}
      isMobileMenuOpened={isMobileMenuOpened}
      isCollapsed={isCollapsed}
    >
      <CollapseButton
        isCollapsed={isCollapsed}
        handleClickCollapse={handleClickCollapse}
      />
      <SiderContentWrapper>
        <LogoWrapper>
          {isCollapsed ? <CollapsedLogoIcon /> : <LogoIcon />}
        </LogoWrapper>
        <NavigationMenuWrapper>
          <NavigationMenu
            menuItems={menuItems}
            isCollapsed={isCollapsed}
            handleMenuItemClick={handleMenuItemClick}
          />
          <Tooltip placement='right' title={isCollapsed && MENU_TITLE.signOut}>
            <SignOutButton onClick={handleClickSignOut}>
              <StyledSignOutIcon
                component={SignOutIcon}
                isCollapsed={isCollapsed}
              />
              <SignOutText isCollapsed={isCollapsed}>
                {MENU_TITLE.signOut}
              </SignOutText>
            </SignOutButton>
          </Tooltip>
        </NavigationMenuWrapper>
      </SiderContentWrapper>
    </StyledSider>
    <OutSideClickHandler
      isMobileMenuOpened={isMobileMenuOpened}
      onClick={toggleIsMobileMenuOpened}
    />
  </>
);

export default SideMenu;
