import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

interface IProps {
  fetchNextPage: () => void;
  hasNextPage?: boolean;
}

export const useFetchNextPage = ({
  hasNextPage,
  fetchNextPage,
}: IProps): ((node?: Element | null) => void) => {
  const { ref, inView } = useInView({
    root: null,
    threshold: 1.0,
    rootMargin: '0px',
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage]);

  return ref;
};
