import { FC } from 'react';

import Badge from '../Badge';
import { StyledCloseCircleFilledIcon } from '../Badge/styled';

const NotVerifiedCustomerBadge: FC = () => (
  <Badge
    type='error-light'
    title='Not verified'
    icon={<StyledCloseCircleFilledIcon />}
  />
);

export default NotVerifiedCustomerBadge;
