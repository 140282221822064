import { FC } from 'react';

import { LabelWrapper, FlagWrapper, Value, CountryName } from './styled';
import { ICountryCodeLabelProps } from './types';
import {
  COUNTRY_CODE_FLAG,
  COUNTRY_CODE_NAME,
  COUNTRY_CODE_VALUE,
} from '../constants';

const CountryCodeLabel: FC<ICountryCodeLabelProps> = ({
  withCountryName = true,
  countryCode,
}) => (
  <LabelWrapper title={COUNTRY_CODE_NAME[countryCode]}>
    <FlagWrapper>
      {COUNTRY_CODE_FLAG[countryCode]}
      <Value>{COUNTRY_CODE_VALUE[countryCode]}</Value>
    </FlagWrapper>
    <CountryName>
      {withCountryName && COUNTRY_CODE_NAME[countryCode]}
    </CountryName>
  </LabelWrapper>
);

export default CountryCodeLabel;
