import { ICustomerInfo } from 'types/customer';
import { IEmployee } from 'types/employee';
import { ISerialNumber } from 'types/serialNumber';
import { formatSerialNumber } from 'utils/format';
import { renderSerialNumberLink } from 'utils/renders';

export const MESSAGES = {
  linkSented:
    'We`ve sent a link to your email address: youremail@gmail.com \nPlease check your mailbox',
  linkExpired:
    'Looks like your reset link has expired. Please request the link again',
  dealerDeletedOrNotExist:
    "Dealer can't be accessed as they were deleted or don't exist.",
  requestDeletedOrNotExist:
    "Request can't be accessed as it was deleted or doesn't exist.",
  serialNumberDeletedOrNotExist:
    "Serial number can't be accessed as it was deleted or doesn't exist.",
  employeeDeletedOrNotExist:
    "Employee can't be accessed as they were deleted or don't exist.",
  customerDeletedOrNotExist:
    "Customer can't be accessed as they were deleted or don't exist.",
};

export const PASSWORD_VALIDATION_MESSAGES = {
  createPassword: 'Please create your password',
  confirmPassword: 'Please confirm your password',
  passwordNotMatch: 'Passwords do not match',
  numbersAndLettersAllowedError: 'Only Latin letters and numbers are allowed',
  passwordLengthError: 'Must contain at least 10 characters',
  oneLatinLetterContainError: 'Must contain at least one Latin letter',
  oneNumberContainError: 'Must contain at least one number',
  oneUppercaseLetterContainError: 'Must contain at least one uppercase letter',
  oneLowerCaseLetterContainError: 'Must contain at least one lowercase letter',
};

export const EMAIL_VALIDATION_MESSAGES = {
  requiredEmail: 'Please enter your email',
  invalidEmail: 'Please enter a valid email',
};

export const NOTIFICATION_SUCCESS_MESSAGE = {
  customerCredentialsUpdated:
    'Account access email was sent to the customer email address. To send the email again, please wait for 2 minutes.',
  employeeCredentialsUpdated:
    'Account access email was sent to the employee email address. To send the email again, please wait for 2 minutes.',
  passwordUpdated: 'Your password was successfully updated.',
  changesSaved: 'Your changes were successfully saved.',
  deletedFile: 'The file was successfully deleted.',
  deletedInvoice:
    'You have successfully deleted previously linked invoice data.',
  deletedDealer: 'The dealer was successfully deleted.',
  createdDealer: 'The dealer was successfully created.',
  deletedRequestBase: 'You have successfully deleted Request',
  deletedCertificate: 'You have successfully deleted certificates PDF',
  requestsMerged: 'The selected Requests were successfully merged.',
  archivedEmployeesDeleted: 'Archived employees successfully deleted.',
  updateProfileDetails: 'Your Profile Details were successfully updated.',
  updateProfileDetailsPassword: 'Your password was successfully updated.',
  updateProfileDetailsPasswordWithSendingEmailError:
    'Your Profile Details were successfully updated. Please note! Error sending corresponding email.',
  addPaymentAndBillingAddress:
    'Payment methods & billing address were successfully updated.',
  updateCustomerBillingAddress: 'Billing address was successfully updated.',
  addCustomerPayment: 'Payment methods were successfully updated.',
  paymentCreated: (serialNumber: string) => (
    <>
      Payment successful. Customer subscription to serial number {serialNumber}
      &nbsp;successfully unlocked.
    </>
  ),
  trialActivated: (serialNumber: string) => (
    <>
      Trial period activated. Customer subscription to serial number&nbsp;
      {serialNumber} successfully unlocked.
    </>
  ),
  serialNumberAttachedToCustomer: (
    customerFullName: string,
    serialNumber: string,
  ) => (
    <>
      Serial number&nbsp;<span>{formatSerialNumber(serialNumber)}</span>
      &nbsp;has been successfully linked to&nbsp;
      <span>{customerFullName}</span>.
    </>
  ),
  updatedCustomerBaseInfoWithEmail: (firstName: string, lastName: string) => (
    <>
      Customer&nbsp;
      <span>
        {firstName} {lastName}
      </span>
      &nbsp;successfully updated. Account access email was sent to the updated
      customer email address.
    </>
  ),
  updatedCustomerBaseInfo: (firstName: string, lastName: string) => (
    <>
      Customer&nbsp;
      <span>
        {firstName} {lastName}
      </span>
      &nbsp;successfully updated.
    </>
  ),
  archivedCustomer: (customer: ICustomerInfo) => (
    <>
      Customer&nbsp;
      <span>
        {customer.firstName} {customer.lastName}
      </span>
      &nbsp;successfully archived.
    </>
  ),
  unarchivedCustomer: (customer: ICustomerInfo) => (
    <>
      Customer&nbsp;
      <span>
        {customer.firstName} {customer.lastName}
      </span>
      &nbsp;successfully unarchived.
    </>
  ),
  deletedCustomer: (customer: ICustomerInfo) => (
    <>
      Customer&nbsp;
      <span>
        {customer.firstName} {customer.lastName}
      </span>
      &nbsp;successfully deleted.
    </>
  ),
  deletedArchivedCustomers: 'Archived customers successfully deleted.',
  archivedEmployee: (employee: IEmployee) => (
    <>
      Employee&nbsp;
      <span>
        {employee.firstName} {employee.lastName}
      </span>
      &nbsp;successfully archived.
    </>
  ),
  unarchivedEmployee: (employee: IEmployee) => (
    <>
      Employee&nbsp;
      <span>
        {employee.firstName} {employee.lastName}
      </span>
      &nbsp;successfully unarchived. Corresponding email was sent to the
      employee email address.
    </>
  ),
  deletedEmployee: (employee: IEmployee) => (
    <>
      Employee&nbsp;
      <span>
        {employee.firstName} {employee.lastName}
      </span>
      &nbsp;successfully deleted.
    </>
  ),
  archivedSN: (serialNumber: ISerialNumber) => (
    <>
      Serial number&nbsp;
      <span>{formatSerialNumber(serialNumber.serialNumber)}</span>
      &nbsp;successfully archived.
    </>
  ),
  unarchivedSN: (serialNumber: ISerialNumber) => (
    <>
      Serial number&nbsp;
      <span>{formatSerialNumber(serialNumber.serialNumber)}</span>
      &nbsp;successfully unarchived.
    </>
  ),
  deletedSN: (serialNumber: ISerialNumber) => (
    <>
      Serial number&nbsp;
      <span>{formatSerialNumber(serialNumber.serialNumber)}</span>
      &nbsp;successfully deleted.
    </>
  ),
  serialNumberLocked: (serialNumber: string) => (
    <>
      Customer access to serial number&nbsp;
      <span>{formatSerialNumber(serialNumber)}</span>&nbsp;successfully locked.
      Data update might take some time. Please, wait a bit till the data is
      updated and refresh the page.
    </>
  ),
  serialNumberRestricted: (serialNumber: string) =>
    `Customer access to serial number ${formatSerialNumber(
      serialNumber,
    )} successfully restricted.`,
  serialNumberAllowed: (serialNumber: string) =>
    `Customer access to serial number ${formatSerialNumber(
      serialNumber,
    )} successfully allowed.`,
  subscriptionLocked: (serialNumber: string) => (
    <>
      Customer subscription to serial number&nbsp;
      <span>{formatSerialNumber(serialNumber)}</span>&nbsp;successfully locked.
    </>
  ),
  subscriptionInvoiceRefunded: (chargebeeId: string) =>
    `Refund by invoice ${chargebeeId} successfully completed.`,
  updateRequest: (serialNumberId: string) => (
    <>
      Serial number and Invoice data successfully allocated to the currency.{' '}
      {renderSerialNumberLink(serialNumberId)}
    </>
  ),
};

export const NOTIFICATION_ERROR_MESSAGE = {
  somethingWentWrong: 'Something went wrong. Please try again later.',
  changesCannotBeSaved: 'Changes cannot be saved. Please try again.',
  notDeletedFile: 'The file could not be deleted. Please try again.',
  notDeletedDealer: 'The dealer could not be deleted. Please try again.',
  notCreatedDealer: 'We could not save your changes. Please try again.',
  notSyncedRequests: "Couldn't sync new requests. Please, try again.",
  notMergedRequests:
    'Could not merge the selected requests: there are notes of different currencies or denominations, from different money machines or with the same note serial number.',
  unhandledMergeError:
    "Sorry, we couldn't merge the selected requests. Please, try again.",
  notDeletedRequest: 'The Request was not deleted. Please try again.',
  failedUpdateRequest: 'The changes cannot be saved. Please try again.',
  failedGeneration: 'Failed to generate. Please try again',
  notDeletedCertificate:
    'The certificates PDF was not deleted. Please try again.',
  notDeletedArchivedEmployees:
    'Archived employees cannot be deleted. Please try again.',
  notCreatedCustomer: 'Changes cannot be saved. Please try again.',
  changesNotSaved: 'Changes cannot be saved. Please try again.',
  noInternetConnection:
    'Unable to connect. Please check your internet connection or try again later. Please note that the data might be reflected incorrectly until the connection is restored.',
  cannotResendEmail:
    "Account access email could not be sent as the time interval limit of 2 minutes didn't pass yet. Please wait a bit more to resend the account access email.",
  invalidCustomerSelected:
    'Changes could not be applied. This customer was just deleted by some other app user. Please, refresh the page to see the up-to-date data.',
};

export const NOTIFICATION_INFO_MESSAGE = {
  signOutInfoMessage:
    'To access the system, please sign in using your actual credentials or contact system admin.',
};

export const PARALLEL_WORK_SUBSTRING = 'some other app user';

export const SERVER_ERROR_STRING = 'Something went wrong. Please try again';
