import { FC } from 'react';

import { CaretUpOutlinedIcon } from 'assets/vectors';

import { StyledMenuIcon, MenuTitleText } from '../styled';
import { StyledCollapse, StyledPanel } from './styled';
import { ICollapseSubmenuItemProps } from './types';
import { COLLAPSE_KEY, ARROW_POSITION } from './constants';

const CollapseSubmenuItem: FC<ICollapseSubmenuItemProps> = ({
  icon,
  menuTitle,
  isCollapsed,
  children,
}) => (
  <StyledCollapse
    bordered={false}
    defaultActiveKey={[COLLAPSE_KEY]}
    expandIcon={() => <CaretUpOutlinedIcon />}
    expandIconPosition={ARROW_POSITION}
  >
    <StyledPanel
      key={COLLAPSE_KEY}
      showArrow={!isCollapsed}
      header={
        <>
          <StyledMenuIcon component={icon} isCollapsed={isCollapsed} />
          <MenuTitleText isCollapsed={isCollapsed}>{menuTitle}</MenuTitleText>
        </>
      }
    >
      {children}
    </StyledPanel>
  </StyledCollapse>
);

export default CollapseSubmenuItem;
