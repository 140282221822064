import { Select as AntdSelect } from 'antd';
import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';

export const StyledSelect = styled(AntdSelect)({
  width: '100%',
  fontSize: '16px',
  color: COLORS.gray900,
  '.ant-select-selection-placeholder': {
    color: COLORS.gray900,
  },
  '.ant-select-selection-item': {
    fontWeight: 500,
  },
  '.ant-select-arrow .anticon:not(.ant-select-suffix)': {
    pointerEvents: 'none',
  },
});
