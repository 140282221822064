import styled from 'styled-components';
import { Typography } from 'antd';

const { Paragraph } = Typography;

export const StyledParagraph = styled(
  ({ isCropped: _isCropped, children, ...props }) => (
    <Paragraph {...props}>{children}</Paragraph>
  ),
)<{ isCropped: boolean }>(({ isCropped }) => ({
  color: 'inherit',
  cursor: isCropped ? 'pointer' : 'inherit',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  wordBreak: 'keep-all',
  '&.ant-typography': {
    marginBottom: 0,
  },
}));
