import { FC } from 'react';
import { useLocation } from 'react-router';

import { useNavigateHome } from 'hooks';
import { TLocation } from 'types/location';

import TechnicalMessage from './TechnicalMessage';
import { getRouteBacklinkPath, getRouteRootHeaderTitle } from './utils';
import { ITechnicalMessageContainerProps } from './types';

export const TechnicalMessageContainer: FC<ITechnicalMessageContainerProps> = ({
  defaultPageTitle,
  isNoInternetPage,
  ...props
}) => {
  const { pathname } = useLocation() as TLocation;

  const handleGoHome = useNavigateHome();
  const handleRefreshPage = () => window.location.reload();

  const pageTitle = getRouteRootHeaderTitle(pathname, defaultPageTitle);
  const backLink = getRouteBacklinkPath(pathname, defaultPageTitle);

  return (
    <TechnicalMessage
      pageTitle={pageTitle}
      backLink={backLink}
      isNoInternetPage={isNoInternetPage}
      handleActionButtonClick={
        isNoInternetPage ? handleRefreshPage : handleGoHome
      }
      {...props}
    />
  );
};

export default TechnicalMessageContainer;
