import styled from 'styled-components';

export const LabelWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

export const FlagWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const Value = styled('span')({
  width: '30px',
  paddingLeft: '6px',
});

export const CountryName = styled('span')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
