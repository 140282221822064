import { FC, useEffect } from 'react';
import { useLocation } from 'react-router';

import { EMPTY_ARRAY } from 'constants/common';
import {
  getSessionStorageValue,
  setSessionStorageValue,
} from 'utils/sessionStorage';
import { useSetHeaderTitle } from 'hooks';
import { TBackButtonHistory } from 'types/sessionStorage';
import { TLocation } from 'hooks/useGoBack/types';

import Header from './Header';
import { IHeaderContainerProps } from './types';

const HeaderContainer: FC<IHeaderContainerProps> = (props) => {
  const { title, subTitle, backLinkPath } = useSetHeaderTitle();
  const location = useLocation() as TLocation;

  useEffect(() => {
    const { pathname, search, state } = location;

    const backButtonHistory = getSessionStorageValue(
      'backButtonHistory',
      EMPTY_ARRAY as TBackButtonHistory[],
    );
    const lastLocation = backButtonHistory[backButtonHistory.length - 1];

    if (lastLocation?.pathname === pathname || state?.routeReplaced) {
      backButtonHistory[backButtonHistory.length - 1] = {
        pathname,
        lastSearch: search,
        tabs: lastLocation?.pathname === pathname ? lastLocation?.tabs : [],
      };

      setSessionStorageValue('backButtonHistory', backButtonHistory);
    } else {
      setSessionStorageValue('backButtonHistory', [
        ...backButtonHistory,
        {
          pathname,
          lastSearch: search,
          tabs: [],
        },
      ]);
    }
  }, [location]);

  return (
    <Header
      headerTitle={title}
      headerSubTitle={subTitle}
      backLinkPath={backLinkPath}
      {...props}
    />
  );
};

export default HeaderContainer;
