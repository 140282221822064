import { FC } from 'react';

import { Padlock } from 'assets/vectors';

import Badge from '../Badge';

const LockedSerialNumberBadge: FC = () => (
  <Badge type='error' title='Locked' icon={<Padlock />} />
);

export default LockedSerialNumberBadge;
