export const COMMON_DATE_FORMAT = 'MMM DD, YYYY';
export const DATE_WITH_TIME_FORMAT = 'MMM DD, YYYY hh:mm A';
export const DATE_WITH_SLASHES_FORMAT = 'MM/DD/YYYY';
export const DATE_WITH_SLASHES_FORMAT_AND_TIME = 'MM/DD/YYYY hh:mm A';

export const MIN_DATE = '01/01/1970';
export const DEFAULT_DATEPICKER_FORMAT = 'MM/DD/YYYY';

export const RANGE_DATE_PICKER_FORMAT = 'YYYY-MM-DD';

export const getResendButtonDisableText = (time: string) =>
  `The Resend button will be available after\n${time} minutes.`;
