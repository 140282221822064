import { useMutation, UseMutationResult } from 'react-query';

import { apiClient, queryClient } from 'api/base';
import { NOTIFICATIONS_TEXT } from 'api/constants';
import { ApiRoutes } from 'api/routes';
import { TAxiosRequestError } from 'api/types';
import { QUERY_KEYS } from 'constants/queryKeys';
import { USER_EMAIL_LS_KEY } from 'constants/localStorage';
import { useNavigateHome } from 'hooks';
import { NotificationType } from 'types/notifications';
import { UserTypes } from 'types/user';
import { transformRoute } from 'utils/routing';
import { openNotificationWithIcon } from 'utils/showNotification';

import { IUseAuthenticatePayload, IUseAuthenticateResponse } from './types';

const useAuthenticate = (): UseMutationResult<
  IUseAuthenticateResponse,
  TAxiosRequestError,
  IUseAuthenticatePayload
> => {
  const triggerGoHome = useNavigateHome();

  const handleAuthenticated = () => {
    queryClient.invalidateQueries([QUERY_KEYS.USER_PROFILE]);
    triggerGoHome();
  };

  const handleAuthenticateError = (error: TAxiosRequestError) => {
    openNotificationWithIcon(
      NotificationType.error,
      error.response?.data?.message || NOTIFICATIONS_TEXT.WRONG,
    );
  };

  return useMutation(
    async (signInPayload: IUseAuthenticatePayload) => {
      const { data: tokenResponse } = await apiClient.post(
        transformRoute(ApiRoutes.SIGN_IN, UserTypes.employee),
        signInPayload,
      );
      localStorage.setItem(USER_EMAIL_LS_KEY, signInPayload.email);

      return tokenResponse;
    },
    {
      onSuccess: handleAuthenticated,
      onError: handleAuthenticateError,
    },
  );
};

export default useAuthenticate;
