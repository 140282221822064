import { TURLSearchParams } from 'types/urlSearchParams';

export const transformWithSearchParams = (
  route: string,
  searchParams: TURLSearchParams,
): string => {
  const searchParamsString = `?${new URLSearchParams(searchParams).toString()}`;

  return route.concat(searchParamsString);
};
