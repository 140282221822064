import dayjs from 'dayjs';

import { COMMON_DATE_FORMAT } from 'constants/datetime';
import { DASH_STRING } from 'constants/common';

export const formatDateString = (
  dateString?: string | Date,
  format = COMMON_DATE_FORMAT,
): string => (dateString ? dayjs(dateString).format(format) : DASH_STRING);

export const formatDateStringToUTCFormat = (
  dateString?: string | null,
): string | null => (dateString ? dayjs(dateString).utc().format() : null);
