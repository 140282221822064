export const APP_ROUTE = {
  samePage: '#',
  unRegistered: '*',
  browserBase: '/',
  appBase: '/*',
  notFound: '/not-found',
  noPermissions: '/no-permissions',
  signIn: '/sign-in',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password/:resetJWT',
  dashboard: '/dashboard',
  requests: '/requests',
  newRequestDetails: '/requests/:requestId/new',
  completeRequestDetails: '/requests/:requestId/complete',
  serialNumbers: '/serial-numbers',
  serialNumberDetails: '/serial-numbers/:serialNumberId',
  subscriptions: '/subscriptions',
  certificates: '/certificates',
  customers: '/customers',
  customerInfo: '/customers/:customerId/info',
  editCustomerBaseInfo: '/customers/:customerId/edit',
  customerDetails: '/customers/:customerId/details',
  createCustomer: '/customers/create',
  employees: '/employees',
  createEmployee: '/employees/create',
  employeeInfo: '/employees/:employeeId/info',
  dealers: '/dealers',
  createDealer: '/dealers/create',
  viewDealerDetails: '/dealers/:dealerId',
  profile: '/profile',
  currencyAnalytics: '/analytics/currency',
  dealerAnalytics: '/analytics/dealer',
  stateAnalytics: '/analytics/state',
  holdingsAnalytics: '/analytics/holdings',
};
