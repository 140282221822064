import { FC } from 'react';

import { BadgeLabel, ContentWrapper } from './styled';
import { IBadgeProps } from './types';

const Badge: FC<IBadgeProps> = ({ type, title, icon }) => (
  <BadgeLabel type={type}>
    <ContentWrapper>
      {icon}
      {title}
    </ContentWrapper>
  </BadgeLabel>
);

export default Badge;
