import { FC } from 'react';

import { UnlockPadlock } from 'assets/vectors';

import Badge from '../Badge';

const UnlockedSerialNumberBadge: FC = () => (
  <Badge type='success' title='Unlocked' icon={<UnlockPadlock />} />
);

export default UnlockedSerialNumberBadge;
