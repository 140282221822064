import { isString } from 'lodash';
import { FC } from 'react';

import InputBase from '../components/InputBase';
import { MAX_DEFAULT_LENGTH } from '../constants';
import { StyledInput } from '../styled';
import { IDefaultInputProps } from '../types';

const DefaultInput: FC<IDefaultInputProps> = ({
  required = true,
  labelText,
  errorMessage,
  placeholder,
  value,
  className,
  disabled,
  ...props
}) => {
  const updatedValue =
    value && isString(value) ? value.substring(0, MAX_DEFAULT_LENGTH) : value;
  return (
    <InputBase
      className={className}
      required={required}
      disabled={disabled}
      labelText={labelText}
      placeholder={placeholder}
      value={value}
      errorMessage={errorMessage}
    >
      <StyledInput
        size='large'
        status={errorMessage && 'error'}
        maxLength={MAX_DEFAULT_LENGTH}
        value={updatedValue}
        disabled={disabled}
        {...props}
      />
    </InputBase>
  );
};

export default DefaultInput;
