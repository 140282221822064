import { FC } from 'react';

import { StyledLink, StyledLeftCircleOutlined, StyledLinkText } from './styled';
import { TBackToLinkProps } from './types';

const BackToLink: FC<TBackToLinkProps> = ({
  to,
  children,
  handleGoBack,
  ...props
}) => (
  <StyledLink to={to} onClick={handleGoBack} {...props}>
    <StyledLeftCircleOutlined />
    <StyledLinkText>{children}</StyledLinkText>
  </StyledLink>
);

export default BackToLink;
