import { useEffect } from 'react';

import { showConfirmModal } from 'utils/showConfirmModal';

import {
  CONFIRM_NAVIGATE_TEXT,
  CONFIRM_NAVIGATE_MODAL_PROPS,
} from './constants';
import { useCallbackPrompt } from '../useCallbackPrompt';
import { IConfirmModalOptions } from './types';

export const useConfirmNavigate = (
  trigger = false,
  options?: IConfirmModalOptions,
) => {
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(trigger);

  useEffect(() => {
    if (showPrompt) {
      showConfirmModal({
        titleText: options?.title || CONFIRM_NAVIGATE_TEXT.title,
        contentText: options?.content || CONFIRM_NAVIGATE_TEXT.content,
        okText: CONFIRM_NAVIGATE_TEXT.confirm,
        cancelText: CONFIRM_NAVIGATE_TEXT.cancel,
        width: CONFIRM_NAVIGATE_MODAL_PROPS.width,
        bodyStyle: {
          height: CONFIRM_NAVIGATE_MODAL_PROPS.height,
        },
        onOk: confirmNavigation,
        onCancel: cancelNavigation,
      });
    }
  }, [showPrompt]);
};
