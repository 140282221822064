import { lazy } from 'react';

export const Dashboard = lazy(() => import('./Dashboard'));
export const Requests = lazy(() => import('./Requests'));
export const NewRequestDetails = lazy(() => import('./NewRequestDetails'));
export const CompleteRequestDetails = lazy(
  () => import('./CompleteRequestDetails'),
);
export const SerialNumbers = lazy(() => import('./SerialNumbers'));
export const SerialNumberDetails = lazy(() => import('./SerialNumberDetails'));
export const Subscriptions = lazy(() => import('./Subscriptions'));
export const Customers = lazy(() => import('./Customers'));
export const CreateCustomer = lazy(() => import('./CreateCustomer'));
export const EditCustomerBaseInfo = lazy(
  () => import('./EditCustomerBaseInfo'),
);
export const CustomerInfo = lazy(() => import('./CustomerInfo'));
export const Employees = lazy(() => import('./Employees'));
export const CreateEmployee = lazy(() => import('./CreateEmployee'));
export const EditEmployeeDetails = lazy(() => import('./EditEmployeeDetails'));
export const Dealers = lazy(() => import('./Dealers'));
export const CreateDealer = lazy(() => import('./CreateDealer'));
export const ViewDealerDetails = lazy(() => import('./ViewDealerDetails'));
export const CurrencyAnalytics = lazy(() => import('./CurrencyAnalytics'));
export const DealersAnalytics = lazy(() => import('./DealersAnalytics'));
export const StatesAnalytics = lazy(() => import('./StatesAnalytics'));
export const HoldingsAnalytics = lazy(() => import('./HoldingsAnalytics'));
export const Profile = lazy(() => import('./Profile'));
export const Certificates = lazy(() => import('./Certificates'));

export { default as NotFound } from './NotFound';
export { default as NoPermissions } from './NoPermissions';
export { default as SignIn } from './SignIn';
export { default as ForgotPassword } from './ForgotPassword';
export { default as ResetPassword } from './ResetPassword';
