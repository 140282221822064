import styled from 'styled-components';
import { Alert } from 'antd';
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';

import { TAlertColorConfig } from './types';

const IconsStyles = {
  marginRight: '10px',
  '& svg': {
    width: '16px',
    height: '16px',
  },
};

export const StyledExclamationCircleOutlined = styled(
  ExclamationCircleOutlined,
)({
  ...IconsStyles,
});

export const StyledCheckOutlined = styled(CheckCircleOutlined)({
  ...IconsStyles,
});

export const StyledAlert = styled(Alert)<TAlertColorConfig>(
  ({ textColor }) => ({
    alignItems: 'center',
    width: '100%',
    maxWidth: '812px',
    padding: '12px 18px',
    fontSize: '16px',
    fontWeight: 500,
    '& .ant-alert-message': {
      color: textColor,
      '& span': {
        fontWeight: 800,
      },
    },
    '@media (max-width: 1024px)': {
      maxWidth: '512px',
    },
    '@media (max-width: 768px)': {
      maxWidth: '384px',
    },
  }),
);
