import { ReactNode } from 'react';

import {
  COMMA_STRING,
  DASH_STRING,
  EMPTY_STRING,
  EMPTY_STRING_WITH_WHITESPACE,
  NOT_AVAILABLE,
} from 'constants/common';
import { DATE_WITH_TIME_FORMAT } from 'constants/datetime';
import CroppedTextWithTooltip from 'components/CroppedTextWithTooltip';
import CroppedTooltip from 'components/CroppedTooltip';
import ImageWithZoom from 'components/ImageWithZoom';
import {
  VerifiedCustomerBadge,
  ActiveSubscriptionBadge,
  CanceledSubscriptionBadge,
} from 'components/Badges';
import { ClockIcon } from 'assets/vectors';
import { banknoteImage, cbiImage } from 'assets/images';
import { formatDateString } from 'utils/format';
import {
  CurrencyType,
  ICurrenciesForRequest,
  ICurrenciesTable,
} from 'types/currencies';
import {
  ISubscription,
  SubscriptionStatus,
  TSubscriptionStatus,
} from 'types/subscriptions';
import { ISerialNumberWithActions } from 'types/serialNumber';
import Tooltip from 'components/Tooltip';
import { checkIfSubscriptionActive } from 'utils/subscriptions';
import { APP_ROUTE } from 'constants/appRoutes';
import { transformRoute } from 'utils/routing';

import {
  ArabicNumbersWrapper,
  BanknotesWrapper,
  StyledLink,
  SubscriptionStatusWrapper,
  Text,
} from './styled';
import {
  formatFloatNumber,
  formatNumberAsMoney,
  formatNumberWithSeparator,
} from '../format/numbers';
import { capitalizeFirstLetter } from '../strings';

export const renderMock = () => DASH_STRING;

export const renderPossibleUndefined = (value: unknown) => value ?? DASH_STRING;

export const renderPossibleArabicSN = (
  value: string,
  currencies: ICurrenciesTable,
) =>
  currencies?.currency === CurrencyType.DINAR ? (
    <ArabicNumbersWrapper>{value}</ArabicNumbersWrapper>
  ) : (
    value
  );

export const renderDate = (
  date: string,
  _record: object,
  _index: number,
  format?: string,
) => (date ? formatDateString(date, format) : DASH_STRING);

export const renderDateWithTime = (date: string) =>
  date ? formatDateString(date, DATE_WITH_TIME_FORMAT) : DASH_STRING;

export const renderSortPlug = () => 0;

export const renderMockBanknotes = (): ReactNode => (
  <BanknotesWrapper>
    <ImageWithZoom src={banknoteImage} />
    <ImageWithZoom src={banknoteImage} />
  </BanknotesWrapper>
);

export const renderCBI = (): ReactNode => <img src={cbiImage} />;

type TFullName = { firstName: string; lastName: string };
export const renderFullName = (
  _value: unknown,
  { firstName, lastName }: TFullName,
): ReactNode => {
  const fullName = `${firstName} ${lastName}`;

  return (
    <CroppedTextWithTooltip title={fullName}>{fullName}</CroppedTextWithTooltip>
  );
};

export const renderNumberWithThousandsCommas = (value: number): string =>
  formatNumberWithSeparator(value, COMMA_STRING);

export const renderFloatNumber = (value: number): string =>
  formatFloatNumber(value);

export const renderNumberWithThousandsSpaces = (value: number): string =>
  formatNumberWithSeparator(value, EMPTY_STRING_WITH_WHITESPACE);

export const renderMoneyDollars = (value: number): string =>
  formatNumberAsMoney(value);

export const renderMoneyCommon = (value: number): string =>
  formatNumberAsMoney(value, 'common');

export const renderTrialStatus = (value: number): string =>
  formatNumberAsMoney(value, 'common');

export const renderCurrencyStatus = (): ReactNode => <VerifiedCustomerBadge />;

export const renderCurrencyCondition = (condition: string) =>
  capitalizeFirstLetter(String(renderPossibleUndefined(condition)));

export const renderSubscriptionStatus = (
  subscriptionStatus: TSubscriptionStatus,
): React.ReactNode => {
  if (!subscriptionStatus) {
    return DASH_STRING;
  }

  const isActiveSubscription = checkIfSubscriptionActive(subscriptionStatus);

  return isActiveSubscription ? (
    <ActiveSubscriptionBadge />
  ) : (
    <CanceledSubscriptionBadge />
  );
};

export const renderSubscriptionStatusWithTrial = (
  subscriptionStatus: TSubscriptionStatus,
  record?: ISubscription,
): React.ReactNode => (
  <SubscriptionStatusWrapper>
    {renderSubscriptionStatus(subscriptionStatus)}
    {subscriptionStatus === SubscriptionStatus.trial &&
      renderTrialTooltip(record?.nextBillingAt)}
  </SubscriptionStatusWrapper>
);

export const renderSNSubscriptionStatus = (
  subscriptionStatus: TSubscriptionStatus,
  isCustomerPage?: boolean,
) =>
  isCustomerPage
    ? renderSubscriptionStatus(subscriptionStatus)
    : renderSubscriptionStatusWithTrial(subscriptionStatus);

export const renderCurrencySeries = (
  series: string,
  { currency }: ICurrenciesForRequest | ISerialNumberWithActions,
): ReactNode =>
  currency !== CurrencyType.DINAR
    ? NOT_AVAILABLE
    : (renderPossibleUndefined(series) as ReactNode);

export const renderAmount = (amount?: number) =>
  amount ? renderNumberWithThousandsCommas(amount) : DASH_STRING;

export const renderCropped = (value: string): ReactNode => (
  <CroppedTooltip title={value}>
    <Text>{value}</Text>
  </CroppedTooltip>
);

export const renderCroppedNumberWithDecorator =
  (decorator: (value: number) => string) => (value: number) =>
    renderCropped(decorator(value || 0));

export const renderTrialTooltip = (date?: Date): ReactNode => {
  const tillDateText = date ? ` till ${formatDateString(date)}` : EMPTY_STRING;

  return (
    <Tooltip title={`Trial${tillDateText}`} placement='right'>
      <ClockIcon />
    </Tooltip>
  );
};

export const renderSerialNumberLink = (serialNumberId: string): ReactNode => (
  <StyledLink
    href={transformRoute(APP_ROUTE.serialNumberDetails, serialNumberId)}
  >
    View serial number.
  </StyledLink>
);
