import { EMPTY_STRING, NUMBERS_REG_EXP } from 'constants/common';

import { DECIMAL_RADIX_VALUE } from './constants';

export const getAmountFromString = (value: string | number): number => {
  const replacedValue = value.toString().replace(NUMBERS_REG_EXP, EMPTY_STRING);

  const amount = parseInt(replacedValue, DECIMAL_RADIX_VALUE);

  return amount;
};
