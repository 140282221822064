import styled from 'styled-components';
import { Input } from 'antd';

import { COLORS } from 'constants/colorPalette';

const { Search } = Input;

export const StyledInput = styled(Search)(({ value }) => ({
  width: '264px',
  minWidth: '264px',
  borderRadius: '4px',
  '& .ant-input-affix-wrapper': {
    height: '40px',
    padding: '6px 12px',
    borderColor: COLORS.gray300,
    '&-focused': {
      borderColor: COLORS.blue500,
    },
  },
  '& .ant-input-clear-icon': {
    lineHeight: 0,
  },
  '& .ant-input-suffix': {
    display: value ? 'inherit' : 'none',
  },
  '& .ant-btn': {
    height: '40px',
    boxShadow: 'none',
    borderColor: COLORS.gray300,
    '&:hover, &:focus': {
      borderColor: COLORS.blue500,
    },
    '& svg': {
      color: COLORS.gray900,
    },
  },
  '& input': {
    fontWeight: 500,
    color: COLORS.gray700,
    letterSpacing: '0.2px',
    '&::placeholder': {
      fontFamily: 'Manrope, sans-serif',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: 1.4,
      color: COLORS.gray400,
    },
  },
}));
