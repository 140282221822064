import { FC } from 'react';
import { Button as AntdButton } from 'antd';

import { TButtonProps } from './types';

export const Button: FC<TButtonProps> = ({ children, loading, ...props }) => (
  <AntdButton type='primary' size='middle' loading={loading} {...props}>
    {!loading && children}
  </AntdButton>
);

export default Button;
