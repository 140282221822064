import { useState, useEffect } from 'react';

import { getIsDesktopVersion } from 'utils/getIsDesktopVersion';

export const useGetIsDesktopVersion = () => {
  const [isDesktopVersion, setIsDesktopVersion] = useState(
    getIsDesktopVersion(),
  );

  useEffect(() => {
    const handleResize = () => {
      setIsDesktopVersion(getIsDesktopVersion());
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isDesktopVersion;
};
