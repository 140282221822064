import axios, { AxiosResponse } from 'axios';
import { QueryClient } from 'react-query';

import { env } from 'config/env';
import { QUERY_KEYS } from 'constants/queryKeys';
import { NULL_TYPE_VALUE } from 'constants/common';
import { NOTIFICATION_ERROR_MESSAGE } from 'constants/messages';
import { ERROR_TYPES, STATUS_CODES } from 'constants/errorCodes';
import { openNotificationWithIcon } from 'utils/showNotification';
import { NotificationType } from 'types/notifications';

import { API_BASE } from './routes';
import { NOTIFICATIONS_TEXT, HIDE_NOTIFICATIONS_DURATION } from './constants';
import { TAxiosRequestError } from './types';

export const queryClient = new QueryClient();

const ACTIONS_NOTIFICATIONS_VISIBLE = {
  INTERNET: true,
  ACCOUNT_SUSPENDED: true,
};

export const apiClient = axios.create({
  headers: { app_type: env.REACT_APP_TYPE },
  baseURL: `${env.REACT_APP_API_URL}${API_BASE}`,
  withCredentials: true,
});

apiClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: TAxiosRequestError) => {
    if (error.code === STATUS_CODES.ERR_NETWORK_CODE) {
      if (ACTIONS_NOTIFICATIONS_VISIBLE.INTERNET) {
        openNotificationWithIcon(
          NotificationType.error,
          NOTIFICATION_ERROR_MESSAGE.noInternetConnection,
        );

        ACTIONS_NOTIFICATIONS_VISIBLE.INTERNET = false;

        setTimeout(
          () => (ACTIONS_NOTIFICATIONS_VISIBLE.INTERNET = true),
          HIDE_NOTIFICATIONS_DURATION,
        );
      }
    }

    const errorType = error?.response?.data?.errorType;

    if (
      errorType === ERROR_TYPES.USER_ARCHIVED ||
      errorType === ERROR_TYPES.USER_EMAIL_CHANGED
    ) {
      if (ACTIONS_NOTIFICATIONS_VISIBLE.ACCOUNT_SUSPENDED) {
        const defaultNotificationMessage =
          errorType === ERROR_TYPES.USER_ARCHIVED
            ? NOTIFICATIONS_TEXT.COMMON_ACCOUNT_SUSPENDED
            : NOTIFICATIONS_TEXT.COMMON_EMAIL_CHANGED;

        openNotificationWithIcon(
          NotificationType.info,
          error?.response?.data?.message ?? defaultNotificationMessage,
        );

        ACTIONS_NOTIFICATIONS_VISIBLE.ACCOUNT_SUSPENDED = false;

        setTimeout(
          () => (ACTIONS_NOTIFICATIONS_VISIBLE.ACCOUNT_SUSPENDED = true),
          HIDE_NOTIFICATIONS_DURATION,
        );
      }
    }

    if (error?.response?.status === STATUS_CODES.UNAUTHORIZED) {
      const userProfile = queryClient.getQueryData(QUERY_KEYS.USER_PROFILE);

      if (userProfile) {
        queryClient.setQueryData(QUERY_KEYS.USER_PROFILE, NULL_TYPE_VALUE);
      }
    }

    return Promise.reject(error);
  },
);
