import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { useBlocker } from './useBlocker';
import { ILocation } from './types';

export const useCallbackPrompt = (
  when: boolean,
): [
  showPrompt: boolean,
  confirmNavigation: () => void,
  cancelNavigation: () => void,
] => {
  const navigate = useNavigate();
  const location = useLocation();

  const [lastLocation, setLastLocation] = useState<ILocation | null>(null);
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const [confirmedNavigation, setConfirmedNavigation] =
    useState<boolean>(false);

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
  }, []);

  const handleBlockedNavigation = useCallback(
    (nextLocation: ILocation) => {
      if (
        !confirmedNavigation &&
        nextLocation.location.pathname !== location.pathname
      ) {
        setShowPrompt(true);
        setLastLocation(nextLocation);
        return false;
      }

      if (
        !confirmedNavigation &&
        nextLocation.location.pathname === location.pathname
      ) {
        nextLocation.retry();
        return false;
      }

      return true;
    },
    [confirmedNavigation],
  );

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location);
    }
  }, [confirmedNavigation, lastLocation]);

  useBlocker(handleBlockedNavigation, when);

  return [showPrompt, confirmNavigation, cancelNavigation];
};
