export const CONFIRM_NAVIGATE_TEXT = {
  title: 'Are you sure you want to leave this page?',
  content: 'All unsaved changes will be lost.',
  confirm: 'Leave page',
  cancel: 'Stay on page',
};

export const CONFIRM_NAVIGATE_MODAL_PROPS = {
  width: '424px',
  height: '187px',
};
