import styled from 'styled-components';

import { COLORS } from 'constants/colorPalette';
import { USER_AVATAR_SIZE } from 'constants/menu';

export const NoAvatarWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: `${USER_AVATAR_SIZE}px`,
  height: `${USER_AVATAR_SIZE}px`,
  background: COLORS.blue500,
});

export const UserInitials = styled('span')({
  fontFamily: 'Manrope, sans-serif',
  fontSize: '12px',
  fontWeight: 800,
  lineHeight: 1.5,
  letterSpacing: '0.4px',
});
