import { FC } from 'react';

import { COLLAPSE_BUTTON_TEXT } from './constants';
import { Tooltip } from './styled';
import { ICollapseButtonTooltipProps } from './types';

const CollapseButtonTooltip: FC<ICollapseButtonTooltipProps> = ({
  debouncedIsCollapsed,
}) => (
  <Tooltip className='collapse-button-tooltip'>
    <span>
      {debouncedIsCollapsed
        ? COLLAPSE_BUTTON_TEXT.EXPAND
        : COLLAPSE_BUTTON_TEXT.COLLAPSE}
    </span>
  </Tooltip>
);

export default CollapseButtonTooltip;
