import { FC, memo } from 'react';

import Tooltip from 'components/Tooltip';
import { EMPTY_ARRAY, EMPTY_STRING } from 'constants/common';

import { TNavigationMenuProps } from '../types';
import { List, StyledNavLink, StyledMenuIcon, MenuTitleText } from './styled';
import CollapseSubmenuItem from './CollapseSubmenuItem';

const NavigationMenu: FC<TNavigationMenuProps> = ({
  menuItems,
  parentMenuTitle,
  isCollapsed,
  handleMenuItemClick,
}) => (
  <List>
    {menuItems.map(({ pathTo, icon, menuTitle, submenu }) => {
      const tooltipTitle = parentMenuTitle
        ? `${parentMenuTitle}: ${menuTitle}`
        : menuTitle;
      const isShowTooltip = isCollapsed && !submenu;

      return (
        <Tooltip
          key={menuTitle}
          placement='right'
          title={isShowTooltip ? tooltipTitle : EMPTY_STRING}
        >
          {submenu?.length ? (
            <li>
              <CollapseSubmenuItem
                icon={icon}
                menuTitle={menuTitle}
                isCollapsed={isCollapsed}
              >
                <NavigationMenu
                  menuItems={submenu || EMPTY_ARRAY}
                  parentMenuTitle={menuTitle}
                  isCollapsed={isCollapsed}
                  handleMenuItemClick={handleMenuItemClick}
                />
              </CollapseSubmenuItem>
            </li>
          ) : (
            <li onClick={handleMenuItemClick}>
              <StyledNavLink to={pathTo}>
                <StyledMenuIcon component={icon} isCollapsed={isCollapsed} />
                <MenuTitleText isCollapsed={isCollapsed}>
                  {menuTitle}
                </MenuTitleText>
              </StyledNavLink>
            </li>
          )}
        </Tooltip>
      );
    })}
  </List>
);

export default memo(NavigationMenu);
