export enum FormScrollNavigationId {
  scrollContainer = 'scrollContainer',
  details = 'details',
  contacts = 'contacts',
  licensing = 'licensing',
}

export interface IMenuItemProps {
  key: string;
  label: string;
}

export interface IFormNavigationProps {
  menuItems?: IMenuItemProps[];
}
