import { FC } from 'react';

import { MENU_TITLE } from 'constants/menu';
import HelmetPageTitle from 'components/HelmetPageTitle';
import { ContentCard } from 'components/UnauthorizedPages';

import { SIGN_IN_TEXT } from './constants';
import SignInForm from './SignInForm';

const SignIn: FC = () => (
  <HelmetPageTitle title={MENU_TITLE.signIn}>
    <ContentCard
      title={SIGN_IN_TEXT.SIGN_IN_TITLE}
      subTitle={SIGN_IN_TEXT.SIGN_IN_TIP}
    >
      <SignInForm />
    </ContentCard>
  </HelmetPageTitle>
);

export default SignIn;
