import { useLocation, useNavigate } from 'react-router';

import { transformToNotFound } from 'utils/routing';

export const useTriggerNotFound = () => {
  const { pathname } = useLocation();
  const navigateTo = useNavigate();

  return (message?: string) =>
    navigateTo(transformToNotFound(pathname), {
      replace: true,
      state: { message, routeReplaced: true },
    });
};
