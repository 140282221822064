import { TPhoneNumberCountryCode } from 'types/phoneNumbers';

export const EMPTY_STRING = '';
export const EMPTY_STRING_WITH_WHITESPACE = ' ';
export const COMMA_STRING = ',';
export const DASH_STRING = '-';
export const NULL_STRING_VALUE = '0';
export const DOT_STRING = '.';
export const EMPTY_ARRAY = [];
export const EMPTY_OBJECT = {};

export const NULL_INDEX = 0;
export const LAST_INDEX = -1;
export const NULL_VALUE = 0;
export const INDEX_INCREMENT = 1;
export const NULL_TYPE_VALUE = null;

export const OBJECT_TYPE = 'object';

export const NUMBERS_REG_EXP = /[^\d]/g;
export const SUBSCRIPTIONS_SEARCH_INPUT_REGEX = /[^a-z0-9_]/gi;

export const MISSING_COUNT_VALUE = 0;
export const REQUIRED_SYMBOL = '*';

export const NOT_AVAILABLE = 'N/A';

export const RESEND_LINK_TIMER_DURATION_MS = 120000;
export const INITIAL_TIMER_VALUE = 0;

export const DEFAULT_PHONE_COUNTRY_CODE: TPhoneNumberCountryCode = 'US';
export const DEFAULT_PHONE_FORMAT = '(###) ###-####';

export const STATES_SELECT_OPTIONS = [
  {
    value: 1,
    key: 'AL',
    label: 'Alabama',
  },
  {
    value: 2,
    key: 'AK',
    label: 'Alaska',
  },
  {
    value: 3,
    key: 'AZ',
    label: 'Arizona',
  },
  {
    value: 4,
    key: 'AR',
    label: 'Arkansas',
  },
  {
    value: 5,
    key: 'CA',
    label: 'California',
  },
  {
    value: 6,
    key: 'CO',
    label: 'Colorado',
  },
  {
    value: 7,
    key: 'CT',
    label: 'Connecticut',
  },
  {
    value: 8,
    key: 'DE',
    label: 'Delaware',
  },
  {
    value: 9,
    key: 'DC',
    label: 'District of Columbia',
  },

  {
    value: 10,
    key: 'FL',
    label: 'Florida',
  },
  {
    value: 11,
    key: 'GA',
    label: 'Georgia',
  },
  {
    value: 12,
    key: 'HI',
    label: 'Hawaii',
  },
  {
    value: 13,
    key: 'ID',
    label: 'Idaho',
  },
  {
    value: 14,
    key: 'IL',
    label: 'Illinois',
  },
  {
    value: 15,
    key: 'IN',
    label: 'Indiana',
  },
  {
    value: 16,
    key: 'IA',
    label: 'Iowa',
  },
  {
    value: 17,
    key: 'KS',
    label: 'Kansas',
  },
  {
    value: 18,
    key: 'KY',
    label: 'Kentucky',
  },
  {
    value: 19,
    key: 'LA',
    label: 'Louisiana',
  },
  {
    value: 20,
    key: 'ME',
    label: 'Maine',
  },
  {
    value: 21,
    key: 'MD',
    label: 'Maryland',
  },
  {
    value: 22,
    key: 'MA',
    label: 'Massachusetts',
  },
  {
    value: 23,
    key: 'MI',
    label: 'Michigan',
  },
  {
    value: 24,
    key: 'MN',
    label: 'Minnesota',
  },
  {
    value: 25,
    key: 'MS',
    label: 'Mississippi',
  },
  {
    value: 26,
    key: 'MO',
    label: 'Missouri',
  },
  {
    value: 27,
    key: 'MT',
    label: 'Montana',
  },
  {
    value: 28,
    key: 'NE',
    label: 'Nebraska',
  },
  {
    value: 29,
    key: 'NV',
    label: 'Nevada',
  },
  {
    value: 30,
    key: 'NH',
    label: 'New Hampshire',
  },
  {
    value: 31,
    key: 'NJ',
    label: 'New Jersey',
  },
  {
    value: 32,
    key: 'NM',
    label: 'New Mexico',
  },
  {
    value: 33,
    key: 'NY',
    label: 'New York',
  },
  {
    value: 34,
    key: 'NC',
    label: 'North Carolina',
  },
  {
    value: 35,
    key: 'ND',
    label: 'North Dakota',
  },
  {
    value: 36,
    key: 'OH',
    label: 'Ohio',
  },
  {
    value: 37,
    key: 'OK',
    label: 'Oklahoma',
  },
  {
    value: 38,
    key: 'OR',
    label: 'Oregon',
  },
  {
    value: 39,
    key: 'PA',
    label: 'Pennsylvania',
  },
  {
    value: 40,
    key: 'RI',
    label: 'Rhode Island',
  },
  {
    value: 41,
    key: 'SC',
    label: 'South Carolina',
  },
  {
    value: 42,
    key: 'SD',
    label: 'South Dakota',
  },
  {
    value: 43,
    key: 'TN',
    label: 'Tennessee',
  },
  {
    value: 44,
    key: 'TX',
    label: 'Texas',
  },
  {
    value: 45,
    key: 'UT',
    label: 'Utah',
  },
  {
    value: 46,
    key: 'VT',
    label: 'Vermont',
  },
  {
    value: 47,
    key: 'VA',
    label: 'Virginia',
  },
  {
    value: 48,
    key: 'WA',
    label: 'Washington',
  },
  {
    value: 49,
    key: 'WV',
    label: 'West Virginia',
  },
  {
    value: 50,
    key: 'WI',
    label: 'Wisconsin',
  },
  {
    value: 51,
    key: 'WY',
    label: 'Wyoming',
  },
];

export const NOT_FOUND_PATH_POSTFIX = '/not-found';
