import { FC, useState } from 'react';

import { NULL_VALUE } from 'constants/common';

import InputBase from './InputBase';
import { IInputBaseContainerProps } from './types';

const InputBaseContainer: FC<IInputBaseContainerProps> = ({
  value,
  errorMessage,
  placeholder,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleBlur = () => setIsFocused(false);
  const handleFocus = () => setIsFocused(true);

  const isError = Boolean(errorMessage?.length);
  const isOccupied =
    isFocused || Boolean(value) || value === NULL_VALUE || Boolean(placeholder);

  return (
    <InputBase
      value={value}
      errorMessage={errorMessage}
      isError={isError}
      isOccupied={isOccupied}
      isFocused={isFocused}
      handleBlur={handleBlur}
      handleFocus={handleFocus}
      {...props}
    />
  );
};

export default InputBaseContainer;
