import styled from 'styled-components';
import { Layout } from 'antd';
import Icon from '@ant-design/icons';

import { COLORS } from 'constants/colorPalette';
import {
  TIsCollapsed,
  TIsMobileMenuOpened,
} from 'components/Layouts/AuthorizedLayout/types';

const { Sider } = Layout;

const LOGO_HEIGHT = '28px';
const LOGO_OFFSET = '48px';

export const StyledSider = styled(
  ({
    isCollapsed: _isCollapsed,
    isMobileMenuOpened: _isMobileMenuOpened,
    children,
    ...props
  }) => <Sider {...props}>{children}</Sider>,
)<TIsCollapsed & TIsMobileMenuOpened>(
  ({ isCollapsed, isMobileMenuOpened }) => ({
    height: '100vh',
    padding: '28px 16px 42px',
    boxShadow:
      '-2px 9px 20px rgba(25, 32, 56, 0.08), 4px 4px 20px rgba(120, 152, 186, 0.04)',
    borderRight: `1px solid ${COLORS.gray100}`,
    backgroundColor: COLORS.white,
    ...(isCollapsed && {
      padding: '28px 0 42px',
    }),
    zIndex: 1,
    '&:hover .collapse-button': {
      ...(!isCollapsed && {
        opacity: 1,
        visibility: 'visible',
        pointerEvents: 'all',
      }),
    },
    '@media (max-width:1024px)': {
      position: 'absolute',
      zIndex: 10,
      transform: 'translateX(-100%)',
      ...(isMobileMenuOpened && { transform: 'translateX(0)' }),
    },
  }),
);

export const OutSideClickHandler = styled(
  ({ isMobileMenuOpened: _isMobileMenuOpened, children, ...props }) => (
    <div {...props}>{children}</div>
  ),
)<TIsMobileMenuOpened>(({ isMobileMenuOpened }) => ({
  opacity: 0,
  visibility: 'hidden',
  pointerEvents: 'none',
  transition: '.2s ease',
  '@media (max-width:1024px)': {
    position: 'absolute',
    top: 0,
    width: '100vw',
    height: '100%',
    marginLeft: '245px',
    zIndex: 10,
    opacity: 1,
    visibility: 'visible',
    pointerEvents: 'all',
    background: 'rgba(108, 108, 108, 0.6)',
    transform: 'translateX(100%)',
    ...(isMobileMenuOpened && { transform: 'translateX(0)' }),
  },
}));

export const SiderContentWrapper = styled('div')({
  position: 'relative',
  height: '100%',
});

export const LogoWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: LOGO_HEIGHT,
  marginBottom: LOGO_OFFSET,
  padding: '0 12px',
});

export const NavigationMenuWrapper = styled('nav')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  height: `calc(100% - ${LOGO_HEIGHT} - ${LOGO_OFFSET})`,
  overflowY: 'auto',
  overflowX: 'hidden',
  direction: 'rtl',
  '> *': {
    direction: 'ltr',
  },
});

export const SignOutButton = styled('button')({
  display: 'flex',
  alignItems: 'center',
  height: '48px',
  padding: '16px 12px',
  marginTop: 'auto',
  background: 'transparent',
  border: 'none',
  borderRadius: '8px',
  transition: '.2s ease',
  '& svg': {
    width: '22px',
    height: '22px',
    fill: COLORS.white,
  },
  '&:hover': {
    background: COLORS.gray50,
  },
});

export const StyledSignOutIcon = styled(
  ({ isCollapsed: _isCollapsed, ...props }) => <Icon {...props} />,
)<TIsCollapsed>(({ isCollapsed }) => ({
  paddingLeft: 0,
  transition: '.2s ease',
  ...(isCollapsed && {
    paddingLeft: '14px',
  }),
}));

export const SignOutText = styled(
  ({ isCollapsed: _isCollapsed, children, ...props }) => (
    <span {...props}>{children}</span>
  ),
)<TIsCollapsed>(({ isCollapsed }) => ({
  paddingLeft: '14px',
  fontWeight: 500,
  color: COLORS.gray700,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  transition: '.2s ease',
  ...(isCollapsed && {
    paddingLeft: 0,
    opacity: 0,
    visibility: 'hidden',
  }),
}));
