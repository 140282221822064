import { FC } from 'react';
import { useLocation } from 'react-router';

import NotFound from './NotFound';
import { TNotFoundLocation } from './types';

const NotFoundContainer: FC = () => {
  const { state } = useLocation() as TNotFoundLocation;

  return <NotFound subTitle={state?.message} />;
};

export default NotFoundContainer;
