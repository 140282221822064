import { IShowModalConfirmProps } from 'utils/showConfirmModal/types';

const SIGN_OUT_CONFIRM_TEXT = {
  title:
    'You are trying to Sign In into the application using the credentials of another user.',
  content: 'To continue, Sign Out of the current session.',
  confirm: 'Confirm Sign Out',
  cancel: 'Cancel',
};

export const SIGN_OUT_CONFIRM_PROPS: IShowModalConfirmProps = {
  titleText: SIGN_OUT_CONFIRM_TEXT.title,
  contentText: SIGN_OUT_CONFIRM_TEXT.content,
  okText: SIGN_OUT_CONFIRM_TEXT.confirm,
  cancelText: SIGN_OUT_CONFIRM_TEXT.cancel,
};
