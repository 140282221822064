import { FC } from 'react';

import { MENU_TITLE } from 'constants/menu';
import TechnicalMessage from 'pages/TechnicalMessage';

import { NO_INTERNET_TEXT } from './constants';

const NoInternet: FC = () => (
  <TechnicalMessage
    isNoInternetPage={true}
    defaultPageTitle={MENU_TITLE.noInternet}
    title={NO_INTERNET_TEXT.CONTENT_TITLE}
    subTitle={NO_INTERNET_TEXT.CONTENT_SUBTITLE}
  />
);

export default NoInternet;
