import { FC, BaseSyntheticEvent } from 'react';

import { EMPTY_STRING } from 'constants/common';
import { TPhoneNumberCountryCode } from 'types/phoneNumbers';

import PhoneNumberInput from './PhoneNumberInput';
import { formatPhoneNumber } from './utils';
import { IPhoneNumberInputContainerProps, TFormatPhoneNumber } from './types';

const PhoneNumberInputContainer: FC<IPhoneNumberInputContainerProps> = ({
  phoneNumberInputProps: {
    value: phoneNumberValue = EMPTY_STRING,
    onChange: _onChangePhoneNumber,
    handleChangePhoneNumberFormik,
    ...phoneNumberInputProps
  },
  countrySelectProps: {
    value: countryCodeValue,
    onChange: _onChangeCountryCode,
    handleChangeCountryCodeFormik,
    ...countrySelectProps
  },
}) => {
  const defaultFormatPhoneNumberProps: TFormatPhoneNumber = {
    value: phoneNumberValue,
    countryCode: countryCodeValue,
    formatType: 'withoutCountryCode',
  };

  const handleChangeCountryCode = (value: unknown) => {
    const updatedPhoneNumber = formatPhoneNumber({
      ...defaultFormatPhoneNumberProps,
      countryCode: value as TPhoneNumberCountryCode,
    });

    if (updatedPhoneNumber) {
      handleChangePhoneNumberFormik(updatedPhoneNumber);
    }

    handleChangeCountryCodeFormik(value);
  };

  const handleChangePhoneNumber = ({ target }: BaseSyntheticEvent) => {
    handleChangePhoneNumberFormik(target.value);
  };

  return (
    <PhoneNumberInput
      phoneNumberInputProps={{
        formattedPhoneNumber: formatPhoneNumber({
          ...defaultFormatPhoneNumberProps,
        }),
        handleChangePhoneNumber,
        ...phoneNumberInputProps,
      }}
      countrySelectProps={{
        countryCode: countryCodeValue,
        handleChangeCountryCode,
        ...countrySelectProps,
      }}
    />
  );
};

export default PhoneNumberInputContainer;
