import { FC } from 'react';
import { isString } from 'lodash';
import { AlertProps as IAlertProps } from 'antd';

import { addDotToEndOfString } from 'utils/format';

import { getAlertDesignByType } from './utils';
import { StyledAlert } from './styled';
import { NETWORK_ERROR_MESSAGE } from './constants';

const CustomAlert: FC<IAlertProps> = ({
  type = 'success',
  message,
  ...props
}) => {
  const alertDesignProps = getAlertDesignByType(type);

  const isAlertHidden = message === NETWORK_ERROR_MESSAGE;

  const formattedMessage = isString(message)
    ? addDotToEndOfString(message)
    : message;

  if (isAlertHidden) {
    return null;
  }

  return (
    <StyledAlert
      showIcon
      type={type}
      message={formattedMessage}
      {...alertDesignProps}
      {...props}
    />
  );
};

export default CustomAlert;
