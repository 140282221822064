import { FC } from 'react';
import {
  Tooltip as AntdTooltip,
  TooltipProps as TAntdTooltipProps,
} from 'antd';

import { DefaultTooltipOverlayStyles } from './styled';

const Tooltip: FC<TAntdTooltipProps> = ({
  children,
  overlayStyle,
  ...props
}) => (
  <AntdTooltip
    {...props}
    overlayStyle={{
      ...overlayStyle,
      ...DefaultTooltipOverlayStyles,
      pointerEvents: 'none',
    }}
  >
    {children}
  </AntdTooltip>
);

export default Tooltip;
