import styled from 'styled-components';
import { Divider } from 'antd';

import { COLORS } from 'constants/colorPalette';
import {
  HEADER_HEIGHT,
  EXPANDED_MENU_WIDTH,
  COLLAPSED_MENU_WIDTH,
} from 'constants/menu';
import { TIsCollapsed } from 'components/Layouts/AuthorizedLayout/types';
import BackToLink from 'components/BackToLink';

import { DEFAULT_HEADER_TITLE_PADDING } from './constants';

export const HeaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: `${HEADER_HEIGHT}px`,
  padding: '22px 64px 24px',
  background: COLORS.white,
  borderBottom: `1px solid ${COLORS.gray200}`,
  '@media (max-width: 1024px)': {
    padding: '22px 32px 24px',
  },
});

export const HeaderTitle = styled('p')<TIsCollapsed>(({ isCollapsed }) => ({
  maxWidth: `calc(60vw - ${
    isCollapsed
      ? DEFAULT_HEADER_TITLE_PADDING
      : DEFAULT_HEADER_TITLE_PADDING +
        EXPANDED_MENU_WIDTH -
        COLLAPSED_MENU_WIDTH
  }px)`,
  margin: '0 20px 0 0',
  fontSize: '24px',
  fontWeight: 600,
  color: COLORS.gray700,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordBreak: 'keep-all',
  transition: '.3s',
  '& span': { fontWeight: 400 },
  '@media (max-width: 1024px)': {
    maxWidth: `calc(60vw - ${DEFAULT_HEADER_TITLE_PADDING}px)`,
    minWidth: '310px',
  },
}));

export const HeaderTitleWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const UserInfoWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  fontSize: '18px',
  fontWeight: 500,
  color: COLORS.gray900,
});

export const UserRole = styled('span')({
  marginRight: '20px',
  textTransform: 'capitalize',
});

export const UserName = styled('p')({
  maxWidth: '200px',
  marginBottom: 0,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordBreak: 'keep-all',
  '@media (max-width: 1076px)': {
    maxWidth: '148px',
  },
});

export const StyledDivider = styled(Divider)({
  height: '20px',
  margin: '0 10px',
  borderColor: COLORS.gray300,
});

export const BurgerButton = styled('button')({
  display: 'none',
  '@media (max-width: 1024px)': {
    position: 'relative',
    display: 'block',
    minWidth: '18px',
    height: '16px',
    marginRight: '26px',
    border: 'none',
    backgroundColor: COLORS.white,
    cursor: 'pointer',
  },
});

const BurgerDefaultLineStyles: object = {
  position: 'absolute',
  left: 0,
  width: '100%',
  height: '2px',
  transform: 'translateY(-50%)',
  backgroundColor: COLORS.gray900,
};

export const BurgerButtonShape = styled('div')({
  top: '50%',
  ...BurgerDefaultLineStyles,
  '&:after': {
    ...BurgerDefaultLineStyles,
    content: '""',
    top: '8px',
    width: '80%',
  },
  '&:before': {
    ...BurgerDefaultLineStyles,
    content: '""',
    top: '-6px',
  },
});

export const StyledBackToLink = styled(BackToLink)({
  marginRight: '40px',
  '@media (max-width: 1024px)': {
    marginRight: '14px',
    '& span:last-child': {
      display: 'none',
    },
  },
});
