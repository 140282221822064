import { FC } from 'react';
import { Popover } from 'antd';

import InputBase from 'components/Inputs/components/InputBase';
import { IPasswordInputProps } from 'components/Inputs/types';

import PopoverContent from './PopoverContent';
import PasswordInputLayout from './PasswordInputLayout';

const PasswordInput: FC<IPasswordInputProps> = ({
  withPopover,
  required = true,
  labelText,
  placeholder,
  value,
  errorMessage,
  ...props
}) => (
  <InputBase
    required={required}
    labelText={labelText}
    placeholder={placeholder}
    value={value}
    errorMessage={errorMessage}
  >
    {withPopover ? (
      <Popover content={<PopoverContent />} trigger='focus' placement='topLeft'>
        <PasswordInputLayout
          value={value}
          errorMessage={errorMessage}
          {...props}
        />
      </Popover>
    ) : (
      <PasswordInputLayout
        value={value}
        errorMessage={errorMessage}
        {...props}
      />
    )}
  </InputBase>
);

export default PasswordInput;
