import { FC } from 'react';

import { useGetUserFromQueryContext } from 'hooks';

import RoleRoute from './RoleRoute';
import { IRoleRouteContainerProps } from './types';

const RoleRouteContainer: FC<IRoleRouteContainerProps> = (props) => {
  const userProfile = useGetUserFromQueryContext();

  const userRole = userProfile?.role;

  return <RoleRoute userRole={userRole} {...props} />;
};

export default RoleRouteContainer;
