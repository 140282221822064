import { Avatar } from 'antd';
import { FC } from 'react';

import { USER_AVATAR_SIZE } from 'constants/menu';

import { TUserAvatarProps } from './types';
import NoAvatar from '../NoAvatar';

const UserAvatar: FC<TUserAvatarProps> = ({ userAvatar, userFullName }) => (
  <Avatar
    size={USER_AVATAR_SIZE}
    icon={
      userAvatar ? <img src={userAvatar} /> : <NoAvatar name={userFullName} />
    }
  />
);

export default UserAvatar;
