import { FC } from 'react';

import { useSignOut } from 'api/auth';
import { MENU_CONFIG } from 'constants/menu';
import { EMPTY_ARRAY } from 'constants/common';
import { Enviroments } from 'types/common';
import { useSetAppContext } from 'hooks';
import { setSessionStorageValue } from 'utils/sessionStorage';
import { SHOW_NOTIFICATION_EVENT_NAME } from 'components/AppContextProvider/constants';
import { env } from 'config/env';

import SideMenu from './SideMenu';
import { ISideMenuContainerProps } from './types';
import { ANIMATION_DURATION_IN_MS } from './constants';

export const SideMenuContainer: FC<ISideMenuContainerProps> = ({
  userRole,
  isCollapsed,
  isMobileMenuOpened,
  toggleIsCollapsed,
  toggleIsMobileMenuOpened,
}) => {
  const { mutate: signOutMutate } = useSignOut();
  const { socket } = useSetAppContext();

  const handleSignOut = () => {
    localStorage.setItem('signOutAdmin', Date.now().toString());

    signOutMutate();

    socket?.off(SHOW_NOTIFICATION_EVENT_NAME);
  };

  const menuItems =
    MENU_CONFIG[env.REACT_APP_NODE_ENV as Enviroments][userRole] ?? EMPTY_ARRAY;

  const handleClickCollapse = () => {
    toggleIsCollapsed();

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, ANIMATION_DURATION_IN_MS);
  };

  const handleMenuItemClick = () => {
    toggleIsMobileMenuOpened();

    setSessionStorageValue('backButtonHistory', EMPTY_ARRAY);
  };

  return (
    <SideMenu
      menuItems={menuItems}
      isCollapsed={isCollapsed}
      isMobileMenuOpened={isMobileMenuOpened}
      toggleIsMobileMenuOpened={toggleIsMobileMenuOpened}
      handleClickSignOut={handleSignOut}
      handleClickCollapse={handleClickCollapse}
      handleMenuItemClick={handleMenuItemClick}
    />
  );
};

export default SideMenuContainer;
