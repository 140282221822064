import { FC } from 'react';
import { PatternFormat } from 'react-number-format';

import { DEFAULT_PHONE_FORMAT } from 'constants/common';

import {
  COUNTRY_CODES_SELECT,
  DEFAULT_LABELS_TEXT,
  DEFAULT_MASK,
  DEFAULT_SELECT_PLACEHOLDER,
  DROPDOWN_SELECT_WIDTH,
  PHONE_NUMBERS_FORMATS,
  SELECTED_OPTION_RENDER,
} from './constants';
import {
  PhoneNumberInputWrapper,
  StyledSelect,
  StyledDefaultInput,
} from './styled';
import { IPhoneNumberInputProps } from './types';

const PhoneNumberInput: FC<IPhoneNumberInputProps> = ({
  phoneNumberInputProps: {
    required: phoneNumberInputRequired = true,
    labelText: phoneNumberInputLabelText = DEFAULT_LABELS_TEXT.PHONE_NUMBERS,
    formattedPhoneNumber,
    handleChangePhoneNumber,
    ...phoneNumberInputProps
  },
  countrySelectProps: {
    allowClear = false,
    required: countryCodeSelectRequired = true,
    labelText: countryCodeSelectLabelText = DEFAULT_LABELS_TEXT.COUNTRY_CODE,
    placeholder: countryCodeSelectPlaceholder = DEFAULT_SELECT_PLACEHOLDER,
    countryCode,
    handleChangeCountryCode,
    ...countrySelectProps
  },
}) => (
  <PhoneNumberInputWrapper>
    <StyledSelect
      allowClear={allowClear}
      optionLabelProp={SELECTED_OPTION_RENDER}
      required={countryCodeSelectRequired}
      labelText={countryCodeSelectLabelText}
      placeholder={countryCodeSelectPlaceholder}
      selectConfig={COUNTRY_CODES_SELECT}
      dropdownMatchSelectWidth={DROPDOWN_SELECT_WIDTH}
      value={countryCode}
      onChange={handleChangeCountryCode}
      {...countrySelectProps}
    />
    <PatternFormat
      type='tel'
      labelText={phoneNumberInputLabelText}
      required={phoneNumberInputRequired}
      format={PHONE_NUMBERS_FORMATS[countryCode] || DEFAULT_PHONE_FORMAT}
      mask={DEFAULT_MASK}
      customInput={StyledDefaultInput}
      value={formattedPhoneNumber}
      onChange={handleChangePhoneNumber}
      {...phoneNumberInputProps}
    />
  </PhoneNumberInputWrapper>
);

export default PhoneNumberInput;
